<template>
  <div>
    <div class="wrapper">
      <comheader :index="2"></comheader>

      <main id="content" role="main">
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age" />
        <input
          type="hidden"
          name="hdnDateTimeText"
          id="hdnDateTimeText"
          value="Date Time (UTC)"
        />
        <input
          type="hidden"
          name="hdnAgeTitle"
          id="hdnAgeTitle"
          value="Click to show Age Format"
        />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          value="Click to show Datetime Format"
        />
        <input
          type="hidden"
          name="hdnTxnText"
          id="hdnTxnText"
          value="Txn Fee"
        />
        <input
          type="hidden"
          name="hdnGasPriceText"
          id="hdnGasPriceText"
          value="Gas Price"
        />
        <input
          type="hidden"
          name="hdnTxnFeeTitle"
          id="hdnTxnFeeTitle"
          value="(Gas Price * Gas Used by Txns) in Ether"
        />
        <input
          type="hidden"
          name="hdnGasPriceTitle"
          id="hdnGasPriceTitle"
          value="Gas Price in Gwei"
        />
        <div class="container">
          <div
            class="d-md-flex justify-content-between align-items-center py-3"
          >
            <div class="mb-1 mb-md-0">
              <h1 class="h4 mb-0">
                Pending Transactions&nbsp;<span
                  class="small text-secondary"
                ></span>
              </h1>
            </div>
          </div>
        </div>
        <div class="container space-bottom-2">
          <span id="ContentPlaceHolder1_lblAdResult"></span>
          <div id="ContentPlaceHolder1_mainrow" class="card">
            <div class="card-body">
              <div
                class="
                  d-flex
                  flex-wrap flex-xl-nowrap
                  text-nowrap
                  align-items-center
                  ml-auto
                "
              ></div>

              <div
                id="ContentPlaceHolder1_topPageDiv"
                class="d-md-flex justify-content-between mb-4"
              >
                <p class="mb-2 mb-md-0">
                  <span class="d-flex align-items-center">
                    <i
                      id="spinwheel"
                      class="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                      style="display: none"
                    ></i>
                    A total of {{ totaltsxpending }} transactions found
                  </span>
                </p>

                <nav aria-label="page navigation">
                  <ul class="pagination pagination-sm mb-0">
                    <li class="page-item" :class="{ disabled: page == 0 }">
                      <span
                        class="page-link"
                        @click="
                          page = 0;
                          changePage();
                        "
                        >First</span
                      >
                    </li>
                    <li class="page-item" :class="{ disabled: page == 0 }">
                      <span
                        @click="
                          page = page - 1;
                          changePage();
                        "
                        class="page-link"
                        ><i class="fa fa-chevron-left small"></i></span
                      ><span class="sr-only">Previous</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link text-nowrap"
                        >Page
                        <strong class="font-weight-medium">{{
                          page + 1
                        }}</strong>
                        of
                        <strong class="font-weight-medium">{{
                          allPages
                        }}</strong></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: page + 1 == allPages }"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Go to Next"
                    >
                      <span
                        class="page-link"
                        @click="
                          page = page + 1;
                          changePage();
                        "
                        aria-label="Next"
                        ><span aria-hidden="True"
                          ><i class="fa fa-chevron-right small"></i
                        ></span>
                        <span class="sr-only">Next</span></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: page + 1 == allPages }"
                    >
                      <span
                        @click="
                          page = allPages - 1;
                          changePage();
                        "
                        class="page-link"
                        ><span aria-hidden="True">Last</span>
                        <span class="sr-only">Last</span></span
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="table-responsive mb-2 mb-md-0">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Txn Hash</th>
                      <th scope="col">Nonce</th>
                      <th scope="col">Last Seen</th>
                      <th scope="col">Gas Limit</th>
                      <th scope="col">Gas Price</th>
                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in txspendingList" :key="index">
                      <td>
                        <span class="hash-tag text-truncate"
                          ><a :href="'/txspendingdetail/' + item.hash">{{
                            item.hash
                          }}</a></span
                        >
                      </td>
                      <td>{{ item.nonce }}</td>

                      <td>
                        <span
                          data-toggle="tooltip"
                          title="Aug-25-2021 03:28:44 AM"
                          >{{ item.created_at | timeFromat }}</span
                        >
                      </td>
                      <td>0</td>
                      <td data-toggle="tooltip" title="99.398576604 Gwei">
                        {{ item.gas_price | valueFormat }}<b></b> Gwei
                      </td>
                      <td>
                        <span
                          class="hash-tag text-truncate"
                          data-toggle="tooltip"
                          title="0xe3c33ff10f8609caecf7042edcf58eedb24937cb"
                          ><a :href="'/txs?address=' + item.from">{{
                            item.from
                          }}</a></span
                        >
                      </td>
                      <td>
                        <span style="white-space: nowrap"
                          ><a
                            class="hash-tag text-truncate"
                            :href="'/txs?address=' + item.to"
                            data-boundary="viewport"
                            data-html="true"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Polygon (Matic): Matic Token&#10;(0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0)"
                            >{{ item.to }}</a
                          ></span
                        >
                      </td>
                      <td>
                        {{ item.amount | fromWei | scientificNotationToString }}
                        {{ nodeData.code }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                id="signInPopUp"
                class="row justify-content-center align-items-center"
                style="display: none"
              >
                <div class="col d-none d-lg-block">
                  <img
                    class="img-fluid mx-4"
                    src="../assets/images/svg/bg/bg1-left-side.svg?v=20.4.3.0"
                    alt=""
                  />
                </div>
                <div class="col-10 col-sm-8 col-lg-7 col-xl-5">
                  <div class="text-center py-5 my-5-alt">
                    <h4 class="font-weight-bold">
                      Sign In for Continued Access
                    </h4>
                    <p class="h5 text-secondary mb-4">
                      No worries, it's absolutely <strong>free</strong> and
                      takes a minute!
                      <br class="-none d-md-inline-block" />
                      Register a
                      <a class="text-primary" href="/register">Free Account</a>
                      today.
                    </p>
                    <div class="mb-3"></div>
                  </div>
                </div>
                <div class="col d-none d-lg-block">
                  <img
                    class="img-fluid mx-4"
                    src="../assets/images/svg/bg/bg1-right-side.svg?v=20.4.3.0"
                    alt=""
                  />
                </div>
              </div>
              <div id="ContentPlaceHolder1_pageRecords">
                <form
                  method="post"
                  action="https://etherscan.io/blocks"
                  id="ctl00"
                >
                  <div class="aspNetHidden">
                    <input
                      type="hidden"
                      name="__EVENTTARGET"
                      id="__EVENTTARGET"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__EVENTARGUMENT"
                      id="__EVENTARGUMENT"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__LASTFOCUS"
                      id="__LASTFOCUS"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__VIEWSTATE"
                      id="__VIEWSTATE"
                      value="NoLdJJEuXPYcjNNUgk92gK9kBxIu8AXRfwEELHl3iDlZ/Op8J0JWaoGux2qvy1Jq17cTaZI60exBIPA1SfW7zR8F0wpwHe5Q0N6qFe7GWCQ="
                    />
                  </div>

                  <div class="aspNetHidden">
                    <input
                      type="hidden"
                      name="__VIEWSTATEGENERATOR"
                      id="__VIEWSTATEGENERATOR"
                      value="1E110816"
                    />
                    <input
                      type="hidden"
                      name="__EVENTVALIDATION"
                      id="__EVENTVALIDATION"
                      value="NN0+1Wu/Viub7SsD8A65Y2vSrQBNbddF+/XSi41Qgvw0JMMl4FkWbAmA6zMYohFhAPg3RuZcaA/SNY3Dby8XZ4hLj94WwaI4TVv1804k5ISMA5ZFT21ypqC6bGtmX8zXkewFgwVDiJ/p3kh0k1KYnXs9jFjLAJEWknSSY2QbG/mD6JLHuskCsSdwUG+dd7zAWAZ7sf+beIhVgwPWNhrdhQ=="
                    />
                  </div>
                  <div class="d-md-flex justify-content-between my-3">
                    <div
                      class="
                        d-flex
                        align-items-center
                        text-secondary
                        mb-2 mb-md-0
                      "
                    >
                      Show
                      <select
                        name="ctl00$ContentPlaceHolder1$ddlRecordsPerPage"
                        v-model="per_page"
                        @change="changePerPage"
                        id="ContentPlaceHolder1_ddlRecordsPerPage"
                        class="custom-select custom-select-xs mx-2"
                      >
                        <option value="10">10</option>
                        <option selected="selected" value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Records
                    </div>
                    <nav aria-label="page navigation">
                      <ul class="pagination pagination-sm mb-0">
                        <li class="page-item" :class="{ disabled: page == 0 }">
                          <span
                            class="page-link"
                            @click="
                              page = 0;
                              changePage();
                            "
                            >First</span
                          >
                        </li>
                        <li class="page-item" :class="{ disabled: page == 0 }">
                          <span
                            @click="
                              page = page - 1;
                              changePage();
                            "
                            class="page-link"
                            ><i class="fa fa-chevron-left small"></i></span
                          ><span class="sr-only">Previous</span>
                        </li>
                        <li class="page-item">
                          <span class="page-link text-nowrap"
                            >Page
                            <strong class="font-weight-medium">{{
                              page + 1
                            }}</strong>
                            of
                            <strong class="font-weight-medium">{{
                              allPages
                            }}</strong></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: page + 1 == allPages }"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Go to Next"
                        >
                          <span
                            class="page-link"
                            @click="
                              page = page + 1;
                              changePage();
                            "
                            aria-label="Next"
                            ><span aria-hidden="True"
                              ><i class="fa fa-chevron-right small"></i
                            ></span>
                            <span class="sr-only">Next</span></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: page + 1 == allPages }"
                        >
                          <span
                            @click="
                              page = allPages - 1;
                              changePage();
                            "
                            class="page-link"
                            ><span aria-hidden="True">Last</span>
                            <span class="sr-only">Last</span></span
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </form>
              </div>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  mt-3
                  mb-4
                "
              >
                <div class="d-flex align-items-center text-secondary"></div>
                <div class="small text-muted"></div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <comfooter></comfooter>
  </div>
</template>

<script>
// @ is an alias to /src
import comheader from "@/components/serach_header";
import comfooter from "@/components/comfooter";
import { get_node_info, get_pending_block } from "@/API/api";
// import web3 from "@/Utils/web3.js"
import Web3 from "web3";
import { timeFrom } from "@/Utils/timeFrom";

export default {
  name: "Home",
  components: {
    comheader,
    comfooter,
  },
  data() {
    return {
      nodeData: {}, //获取节点信息
      page: 0,
      per_page: 25,
      txspendingList: [],
      totaltsxpending: 0,
      allPages: 1,
    };
  },
  mounted() {
    let that = this;
    that.getNodeData();
    that.get_pending_block();
  },
  filters: {
    str_Address(address) {
      let str1 = address.slice(0, 4);
      let str2 = address.slice(-4);

      return str1 + "..." + str2;
    },
    timeFromat(date) {
      return timeFrom(date);
    },
    fromWei(value) {
      if (value == null) {
        return 0;
      }

      var web3 = new Web3();
      var value = web3.utils.fromWei(value.toString(), "ether");

      return parseFloat(parseFloat(value).toFixed(8, 2));
    },
    valueFormat(data) {
      var res = data / Math.pow(10, 8);
      return res;
    },
    scientificNotationToString(param) {
      let strParam = String(param);
      let flag = /e/.test(strParam);
      if (!flag) return param;

      // 指数符号 true: 正，false: 负
      let sysbol = true;
      if (/e-/.test(strParam)) {
        sysbol = false;
      }
      // 指数
      let index = Number(strParam.match(/\d+$/)[0]);
      // 基数
      let basis = strParam.match(/^[\d]+/)[0].replace(/\./, "");

      if (sysbol) {
        return basis.padEnd(index + 1, 0);
      } else {
        return basis.padStart(index + basis.length, 0).replace(/^0/, "0.");
      }
    },
  },
  methods: {
    changePerPage() {
      let that = this;
      that.page = 0;
      that.get_pending_block();
    },
    changePage() {
      let that = this;
      that.get_pending_block();
    },
    getNodeData() {
      let that = this;
      get_node_info().then((res) => {
        that.nodeData = res;
      });
    },
    get_pending_block() {
      let that = this;
      get_pending_block(that.per_page, that.page).then((res) => {
        that.txspendingList = res.content;

        that.totaltsxpending = res.totalElements;
        that.allPages = res.totalPages;
      });
    },
  },
};
</script>
