<template>
  <header id="header" class="u-header">
    <div class="u-header__section">
      <div id="logoAndNav" class="container">
        <nav
          class="
            js-mega-menu
            navbar navbar-expand-md
            u-header__navbar u-header__navbar--no-space
            hs-menu-initialized hs-menu-horizontal hs-pc-state
          "
        >
          <div class="w-lg-auto">
            <a
              class="navbar-brand pt-md-0"
              href="/"
              target="_parent"
              aria-label="Etherscan"
            >
              <img
                id="logo-header"
                width="160"
                src="../assets/images/h-logo.svg"
                alt="Etherscan Logo"
              />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="navbar-toggler btn u-hamburger"
              @click="ShoeMenu"
              :class="{ collapsed: !isSHowMenu }"
            >
              <span id="hamburgerTrigger" class="u-hamburger__box">
                <span class="u-hamburger__inner"></span>
              </span>
            </button>
          </div>
          <div class="d-flex flex-column w-100">
            <div
              id="navBar"
              class="
                collapse
                navbar-collapse
                u-header__navbar-collapse
                order-md-2
              "
              :class="{ show: isSHowMenu }"
            >
              <ul class="navbar-nav u-header__navbar-nav px-2 px-md-0">
                <li
                  id="LI_default"
                  class="nav-item u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 1 }"
                >
                  <a
                    class="nav-link u-header__nav-link"
                    href="/"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Home</a
                  >
                </li>
                <li
                  id="LI_blockchain"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 2 }"
                >
                  <a
                    id="blockchainMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Blockchain</a
                  >
                  <ul
                    id="blockchainSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                      animated
                      fadeOut
                    "
                    aria-labelledby="blockchainMegaMenu"
                    style="min-width: 230px; display: none"
                  >
                    <li id="LI12">
                      <a
                        href="/txs"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Txns</a
                      >
                    </li>
                    <li id="LI16">
                      <a
                        href="/txsPending"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Pending Txns</a
                      >
                    </li>

                    <li class="dropdown-divider"></li>
                    <li id="LI_blocks">
                      <a
                        id="navBlocks"
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/blocks"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-controls="navSubBlocks"
                        >View Blocks</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>

                    <a
                      id="navBlocks"
                      class="nav-link u-header__sub-menu-nav-link"
                      href="/accounts"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-controls="navSubBlocks"
                      >Top Accounts</a
                    >
                  </ul>
                </li>
                <li
                  id="LI_Tokens"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 3 }"
                >
                  <a
                    id="testTokensMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="testTokensSubMenu"
                    >Tokens</a
                  >
                  <ul
                    id="testTokensSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="testTokensMegaMenu"
                    style="min-width: 230px"
                  >
                    <li id="LI18">
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/tokens"
                        >PRC20 Top Tokens</a
                      >
                    </li>
                    <li id="LI40">
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/transfers"
                        >View PRC20 Transfers</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="form1">
              <form
                class="w-100 w-lg-60 order-md-1 ml-md-auto mt-2 mb-2 mb-md-0"
                action="/search"
                method="GET"
                autocomplete="off"
                spellcheck="false"
                @submit.prevent
              >
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend d-none d-md-block">
                    <select
                      name="f"
                      id="f"
                      class="
                        custom-select custom-select-sm custom-arrow-select
                        input-group-text
                        font-size-base
                        filterby
                      "
                    >
                      <option selected="" value="0">All Filters</option>
                      <option value="1">Addresses</option>
                      <option value="2">Txhash</option>
                      <option value="3">BlockNum</option>
                      <!--
                      <option value="2">Tokens</option>
                      <option value="3">Name Tags</option>
                      <option value="4">Labels</option>
                      <option value="5">Websites</option>
                      -->
                    </select>
                  </div>
                  <input
                    id="txtSearchInput"
                    type="text"
                    class="
                      form-control
                      searchautocomplete
                      ui-autocomplete-input
                      list-unstyled
                      py-3
                      mb-0
                    "
                    autocomplete="off"
                    spellcheck="false"
                    name="q"
                    placeholder="Search by Address / Txn Hash / Block"
                    aria-label="Search by Address / Txhash / Block / Token / Ens"
                    aria-describedby="button-header-search"
                    maxlength="68"
                    @keyup.enter.prevent="search($event)"
                  />

                  <div class="input-group-append">
                    <button
                      class="btn btn-primary"
                      @click="toAnother"
                      style="cursor: pointer"
                    >
                      <span class="fas fa-search"></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </nav>
        <nav
          class="
            js-mega-menu
            navbar navbar-expand-md
            u-header__navbar u-header__navbar--no-space
            hs-menu-initialized hs-menu-horizontal hs-mobile-state
          "
        >
          <div class="w-lg-auto">
            <a
              class="navbar-brand pt-md-0"
              href="/"
              target="_parent"
              aria-label="Etherscan"
            >
              <img
                id="logo-header"
                width="160"
                src="../assets/images/h-logo.svg"
                alt="Etherscan Logo"
              />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="navbar-toggler btn u-hamburger"
              @click="ShoeMenu"
              :class="{ collapsed: !isSHowMenu }"
            >
              <span id="hamburgerTrigger" class="u-hamburger__box">
                <span class="u-hamburger__inner"></span>
              </span>
            </button>
          </div>
          <div class="d-flex flex-column w-100">
            <div
              id="navBar"
              class="
                collapse
                navbar-collapse
                u-header__navbar-collapse
                order-md-2
              "
              :class="{ show: isSHowMenu }"
            >
              <ul class="navbar-nav u-header__navbar-nav px-2 px-md-0">
                <li
                  id="LI_default"
                  class="nav-item u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 1 }"
                >
                  <a
                    class="nav-link u-header__nav-link"
                    href="/"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Home</a
                  >
                </li>
                <li
                  id="LI_blockchain"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 2 }"
                >
                  <a
                    id="blockchainMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                    >Blockchain</a
                  >
                  <ul
                    id="blockchainSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                      animated
                      fadeOut
                    "
                    aria-labelledby="blockchainMegaMenu"
                    style="min-width: 230px; display: none"
                  >
                    <li id="LI12">
                      <a
                        href="/txs"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Txns</a
                      >
                    </li>
                    <li id="LI16">
                      <a
                        href="/txsPending"
                        class="nav-link u-header__sub-menu-nav-link"
                        >View Pending Txns</a
                      >
                    </li>

                    <li class="dropdown-divider"></li>
                    <li id="LI_blocks">
                      <a
                        id="navBlocks"
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/blocks"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-controls="navSubBlocks"
                        >View Blocks</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>
                    <li id="LI_blocks">
                      <a
                        id="navBlocks"
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/accounts"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-controls="navSubBlocks"
                        >Top Accounts</a
                      >
                    </li>
                  </ul>
                </li>
                <li
                  id="LI_Tokens"
                  class="nav-item hs-has-sub-menu u-header__nav-item"
                  data-event="hover"
                  data-animation-in="slideInUp"
                  data-animation-out="fadeOut"
                  :class="{ active: index == 3 }"
                >
                  <a
                    id="testTokensMegaMenu"
                    class="
                      nav-link
                      u-header__nav-link u-header__nav-link-toggle
                    "
                    href="javascript:;"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="testTokensSubMenu"
                    >Tokens</a
                  >
                  <ul
                    id="testTokensSubMenu"
                    class="
                      hs-sub-menu
                      u-header__sub-menu u-header__sub-menu--spacer
                    "
                    aria-labelledby="testTokensMegaMenu"
                    style="min-width: 230px"
                  >
                    <li id="LI18">
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/tokens"
                        >PRC20 Top Tokens</a
                      >
                    </li>
                    <li id="LI40">
                      <a
                        class="nav-link u-header__sub-menu-nav-link"
                        href="/transfers"
                        >View PRC20 Transfers</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="form1">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend d-none d-md-block">
                  <select
                    name="f"
                    class="
                      custom-select custom-select-sm custom-arrow-select
                      input-group-text
                      font-size-base
                      filterby
                    "
                  >
                    <option selected="" value="0">All Filters</option>
                    <option value="1">Addresses</option>
                    <option value="2">Txhash</option>
                    <option value="3">BlockNum</option>
                    <!--
                      <option value="2">Tokens</option>
                      <option value="3">Name Tags</option>
                      <option value="4">Labels</option>
                      <option value="5">Websites</option>
                      -->
                  </select>
                </div>
                <input
                  type="text"
                  class="
                    form-control
                    searchautocomplete
                    ui-autocomplete-input
                    list-unstyled
                    py-3
                    mb-0
                  "
                  autocomplete="off"
                  spellcheck="false"
                  name="q"
                  placeholder="Search by Address / Txn Hash / Block / Token / Ens"
                  aria-label="Search by Address / Txhash / Block / Token / Ens"
                  aria-describedby="button-header-search"
                  maxlength="68"
                  @keyup.enter.prevent="search($event)"
                />

                <div class="input-group-append">
                  <button class="btn btn-primary">
                    <span class="fas fa-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import {
  get_transaction_by_from_or_to,
  get_transaction_by_hash,
  get_block_by_height,
} from "@/API/api";
export default {
  data() {
    return {
      screenWidth: 0,
      isSHowMenu: false,
      page: 0,
      per_page: 25,
      addr: "",
    };
  },
  props: {
    index: {
      default: 1,
    },
  },
  methods: {
    ShoeMenu() {
      let that = this;
      that.isSHowMenu = !that.isSHowMenu;
    },
    toAnother() {
      let that = this;
      let textOld = document.querySelector("#txtSearchInput").value;
      if (textOld == "") return;
      let text = textOld.trim();
      var value = document.querySelector("#f").value;
      if (value == 0) {
        if (!that.isNumber(text)) {
          if (text.length < 45) {
            get_transaction_by_from_or_to(that.per_page, that.page, text).then(
              (res) => {
                that.$router.push("/txs?address=" + text);
              }
            );
          } else {
            get_transaction_by_hash(text).then((res) => {
              that.$router.push("/tx/" + text);
            });
          }
        } else {
          get_block_by_height(text).then((res) => {
            that.$router.push("/blocksdetail/" + text);
          });
        }
      } else if (value == 1) {
        get_transaction_by_from_or_to(that.per_page, that.page, text).then(
          (res) => {
            that.$router.push("/txs?address=" + text);
          }
        );
      } else if (value == 2) {
        get_transaction_by_hash(text).then((res) => {
          that.$router.push("/tx/" + text);
        });
      } else if (value == 3) {
        if (!(parseInt(text, 10) == text)) {
          text = "100000000";
        }
        get_block_by_height(text).then((res) => {
          that.$router.push("/blocksdetail/" + text);
        });
      }
    },
    search(e) {
      let that = this;
      var keyCode = window.event ? e.keyCode : e.which;
      if (keyCode == 13) {
        that.toAnother();
      }
    },
    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      var regNeg =
        /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    let that = this;
    $(".hs-mobile-state .u-header__nav-item").click(function () {
      if ($(this).hasClass("hs-sub-menu-opened")) {
        $(this).removeClass("hs-sub-menu-opened");
        $(this).children(".u-header__sub-menu").removeClass("slideInUp");
        $(this).children(".u-header__sub-menu").addClass("fadeOut");
        $(this).children(".u-header__sub-menu").css({ display: "none" });
      } else {
        $(this).addClass("hs-sub-menu-opened");
        $(this).children(".u-header__sub-menu").css({ display: "block" });
        $(this).children(".u-header__sub-menu").removeClass("fadeOut");
        $(this).children(".u-header__sub-menu").addClass("slideInUp");

        $(this).siblings("li").removeClass("hs-sub-menu-opened");
        $(this)
          .siblings("li")
          .children(".u-header__sub-menu")
          .removeClass("slideInUp");
        $(this)
          .siblings("li")
          .children(".u-header__sub-menu")
          .addClass("fadeOut");
        $(this)
          .siblings("li")
          .children(".u-header__sub-menu")
          .css({ display: "none" });
      }
    });

    $(".hs-pc-state .u-header__nav-item").hover(
      function () {
        if ($(this).hasClass("hs-has-mega-menu")) {
          $(this).addClass("hs-mega-menu-opened");
        } else {
          $(this).addClass("hs-sub-menu-opened");
        }

        $(this).children(".u-header__sub-menu").css({ display: "block" });
        $(this).children(".u-header__sub-menu").removeClass("fadeOut");

        $(this).children(".u-header__sub-menu").addClass("slideInUp");
      },
      function () {
        if ($(this).hasClass("hs-has-mega-menu")) {
          $(this).removeClass("hs-mega-menu-opened");
        } else {
          $(this).removeClass("hs-sub-menu-opened");
        }

        $(this).children(".u-header__sub-menu").removeClass("slideInUp");
        $(this).children(".u-header__sub-menu").addClass("fadeOut");
        $(this).children(".u-header__sub-menu").css({ display: "none" });
      }
    );
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
};
</script>

