<template>
  <div>
    <serachheader></serachheader>
    <div class="wrapper search_wraper">
  <main id="content" role="main">
<input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age">
<input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" value="Date Time (UTC)">
<input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" value="Click to show Age Format">
<input type="hidden" name="hdnDateTimeTitle" id="hdnDateTimeTitle" value="Click to show Datetime Format">
<input type="hidden" name="hdnTxnText" id="hdnTxnText" value="Txn Fee">
<input type="hidden" name="hdnGasPriceText" id="hdnGasPriceText" value="Gas Price">
<input type="hidden" name="hdnTxnFeeTitle" id="hdnTxnFeeTitle" value="(Gas Price * Gas Used by Txns) in Ether">
<input type="hidden" name="hdnGasPriceTitle" id="hdnGasPriceTitle" value="Gas Price in Gwei">
<div class="container d-lg-flex align-items-lg-center min-height-550 space-1">
<div class="w-lg-60 w-xl-50">

<div class="mb-5">
<h1 class="text-primary font-weight-normal">Search not <span class="font-weight-semi-bold">found</span></h1>
<p class="mb-0">Oops! The search string you entered was: <b>尺寸</b></p><p>Sorry! This is an invalid search string.</p><p></p>
<p>If you think this is a problem with us, please <a href="/contactus">tell us</a>.</p>
</div>

<a class="btn btn-primary btn-wide transition-3d-hover" href="/">Back Home</a>
</div>
</div>
</main>
    </div>
    <comfooter></comfooter>
    <a
      class="js-go-to u-go-to animated"
      href="https://etherscan.io/blocks#"
      data-position='{"bottom": 20, "right": 15 }'
      data-type="fixed"
      data-offset-top="400"
      data-compensation="#header"
      data-show-effect="slideInUp"
      data-hide-effect="slideOutDown"
      style="
        display: none;
        position: fixed;
        bottom: 20px;
        right: 15px;
        opacity: 0;
      "
    >
      <span class="fa fa-arrow-up u-go-to__inner"></span>
    </a>
  </div>
</template>
<script>
import comfooter from "@/components/comfooter";
import serachheader from "@/components/serach_header.vue";
import { get_block_list, get_node_info } from "@/API/api";
import { timeFrom } from "@/Utils/timeFrom";

export default {
  name: "block",

  data() {
    return {
      nodeData: {}, //获取节点信息
      page: 0,
      per_page: 25,
      blockList: [],
      totalBlocks: 0,
      allPages: 1,
      firstBlock: {},
      endBlock: {},
    };
  },
  components: {
    comfooter,
    serachheader,
  },
  filters: {
    str_Address(address) {
      let str1 = address.slice(0, 4);
      let str2 = address.slice(-4);

      return str1 + "..." + str2;
    },
    timeFromat(date) {
      return timeFrom(date);
    },
  },
  mounted() {
    let that = this;
    that.getNodeData();
    that.get_block_list();
  },
  methods: {
    changePerPage() {
      let that = this;
      that.page = 0;
      that.get_block_list();
    },
    changePage() {
      let that = this;
      that.get_block_list();
    },
    getNodeData() {
      let that = this;
      get_node_info().then((res) => {
        that.nodeData = res;
      });
    },
    get_block_list() {
      let that = this;
      get_block_list(that.per_page, that.page).then((res) => {
        that.blockList = res.data.content;
        that.totalBlocks = res.data.totalElements;
        that.allPages = res.data.totalPages;
        if (that.blockList.length > 0) {
          that.firstBlock = that.blockList[0];
          that.endBlock = that.blockList[that.blockList.length - 1];
        }
      });
    },
  },
};
</script>

