<template>
  <div>
    <comheader :index="2"></comheader>
    <div class="wrapper">
      <main class="content" role="main">
        <div class="container py-3 mn-b3">
          <div class="d-lg-flex justify-content-between align-items-center">
            <div class="mb-3 mb-lg-0">
              <h1 class="h4 mb-0">Transaction Details</h1>
            </div>
          </div>
        </div>
        <div class="container space-bottom-2">
          <div class="card">
            <div
              class="
                card-header
                sticky-card-header
                d-flex
                justify-content-between
                p-0
              "
            >
              <ul
                class="nav nav-custom nav-borderless nav_tabs1"
                id="nav_tabs"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    aria-controls="home"
                    aria-selected="true"
                    onclick="javascript:;"
                    >Overview</a
                  >
                </li>
              </ul>
            </div>
            <div id="ContentPlaceHolder1_maintable" class="card-body">
              <div class="row align-items-center mt-1">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="A TxHash or transaction hash is a unique 66 characters identifier that is generated whenever a transaction is executed."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i>
                  </el-popover>
                  Transaction Hash:
                </div>
                <div class="col-md-9">
                  <span id="spanTxHash" class="mr-1">{{
                    txpendingInfo.hash
                  }}</span>

                  <el-tooltip
                    content="Copy Txn Hash to clipboard"
                    placement="top"
                  >
                    <a
                      class="js-clipboard text-muted"
                      href="javascript:;"
                      v-clipboard:copy="txpendingInfo.hash"
                      v-clipboard:success="onCopy1"
                      v-clipboard:error="onError"
                    >
                      <span
                        id="spanTxHashLinkIcon"
                        class="far"
                        :class="{
                          'fa-copy': !isSuccess_copy1,
                          'fa-check-circle-o': isSuccess_copy1,
                        }"
                      ></span>
                    </a>
                  </el-tooltip>
                  {{ isSuccess_copy1 ? "Copied" : "" }}
                </div>
              </div>

              <hr class="hr-space" />
              <div class="row align-items-center mn-3">
                <div
                  class="
                    col-auto col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="The status of the transaction."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Status:
                </div>
                <!-- <div class="col col-md-9">
                  <span v-if="txpendingInfo.status == 1">Success</span>
                  <span v-if="txpendingInfo.status == 0">Pending</span>
                </div> -->
                <div class="col-md-9">
                  <span
                    v-if="txpendingInfo.status == 1"
                    class="u-label u-label--sm u-label--success rounded"
                    ><i class="fa fa-check-circle mr-1"></i>Success</span
                  >
                  <span
                    v-if="txpendingInfo.status == 0"
                    class="u-label u-label--sm u-label--secondary rounded"
                    ><i class="fa fa-dot-circle mr-1"></i> Pending</span
                  >
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="The number of the block in which the transaction was recorded. Block confirmation indicate how many blocks since the transaction is mined."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Block:
                </div>
                <div class="col-md-9">
                  <a href="javascript:;">{{ txpendingInfo.blockNumber }}</a>
                </div>
              </div>

              <div id="ContentPlaceHolder1_divTimeStamp"></div>
              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="The sending party of the transaction (could be from a contract address)."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >From:
                </div>
                <div class="col-md-9">
                  <span id="spanFromAdd" style="display: none">{{
                    txpendingInfo.from
                  }}</span
                  ><a id="addressCopy" class="mr-1" :href="'/txs?address=' + txpendingInfo.from">{{
                    txpendingInfo.from
                  }}</a>
                  <el-tooltip
                    content="Copy Txn Hash to clipboard"
                    placement="top"
                  >
                    <a
                      class="js-clipboard text-muted"
                      href="javascript:;"
                      v-clipboard:copy="txpendingInfo.from"
                      v-clipboard:success="onCopy2"
                      v-clipboard:error="onError"
                    >
                      <span
                        id="spanTxHashLinkIcon"
                        class="far"
                        :class="{
                          'fa-copy': !isSuccess_copy2,
                          'fa-check-circle-o': isSuccess_copy2,
                        }"
                      ></span>
                    </a>
                  </el-tooltip>
                  {{ isSuccess_copy2 ? "Copied" : "" }}
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="The receiving party of the transaction (could be a contract address)."
                    placement="top"
                    max-width="200"
                    width="200"
                    trigger="hover"
                  >
                  </el-popover>
                  <el-popover
                    popper-class="new-ele-popper"
                    content="The receiving party of the transaction (could be a contract address)."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >To:
                </div>
                <div class="col-md-9">
                  <a
                    v-if="isContract == 0"
                    id="contractCopy"
                    :href="'/txs?address=' + txpendingInfo.to"
                    class="wordwrap mr-1"
                    >{{ txpendingInfo.to }}</a
                  >
                  <a
                    v-if="isContract != 0"
                    id="contractCopy"
                    href="javascript:;"
                    class="wordwrap mr-1"
                    >(Contract) {{ txpendingInfo.to }}</a
                  >
                  <el-tooltip
                    content="Copy To Address to clipboard"
                    placement="top"
                  >
                    <a
                      class="js-clipboard text-muted ml-1"
                      href="javascript:;"
                      v-clipboard:copy="txpendingInfo.to"
                      v-clipboard:success="onCopy3"
                      v-clipboard:error="onError"
                    >
                      <span
                        id="spanToAddResult"
                        class="far"
                        :class="{
                          'fa-copy': !isSuccess_copy3,
                          'fa-check-circle-o': isSuccess_copy3,
                        }"
                      ></span>
                    </a>
                  </el-tooltip>
                  {{ isSuccess_copy3 ? "Copied" : "" }}
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row align-items-center mn-3">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction."
                    placement="top"
                    max-width="250"
                    width="250"
                    trigger="hover"
                  >
                  </el-popover
                  ><el-popover
                    popper-class="new-ele-popper"
                    content="The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Value:
                </div>
                <div class="col-md-9">
                  <span id="ContentPlaceHolder1_spanValue">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="
                        'The amount of ' +
                        nodeData.code +
                        ' to be transferred to the recipient with the transaction'
                      "
                      placement="top"
                      width="200"
                    >
                      <span
                        data-toggle="tooltip"
                        title=""
                        data-original-title=""
                        ><span
                          class="
                            u-label u-label--value u-label--secondary
                            text-dark
                            rounded
                            mr-1
                          "
                          >{{ txpendingInfo.value | fromWei }}
                          {{ nodeData.code }}</span
                        >
                      </span>
                    </el-tooltip>
                    <!-- ($0.00) -->
                  </span>
                </div>
              </div>

              <div id="ContentPlaceHolder1_divTxFee"></div>
              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="Cost per unit of gas specified for the transaction, in Ether and Gwei. The higher the gas price the higher chance of getting included in a block."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Gas Price:
                </div>
                <div class="col-md-9">
                  <span
                    id="ContentPlaceHolder1_spanGasPrice"
                    title="The price offered to the miner to purchase this amount of GAS （per GAS）"
                    data-toggle="tooltip"
                    >{{ txpendingInfo.gasprice | valueFormat
                    }}<b></b> Gwei</span
                  >
                </div>
              </div>

              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="Introduced in EIP2718, this defines a transaction type that is an envelope for current and future transaction types."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Txn Type:
                </div>
                <div class="col-md-9">{{ txpendingInfo.type }}</div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="Maximum amount of gas provided for the transaction. For normal Eth transfers, the value is 21,000. For contract this value is higher and bound by block gas limit."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Gas Limit:
                </div>
                <div class="col-md-9">
                  <span
                    id="ContentPlaceHolder1_spanGasLimit"
                    title="The amount of GAS supplied for this transaction to happen"
                    data-toggle="tooltip"
                    >0</span
                  >
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="The exact units of gas that was used for the transaction."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Gas Used by Transaction:
                </div>
                <div class="col-md-9">
                  <span
                    id="ContentPlaceHolder1_spanGasUsedByTxn"
                    title="The amount of GAS used by this specific transaction alone"
                    data-toggle="tooltip"
                    >{{ txpendingInfo.gasUsed }}</span
                  >
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row mn-3">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be the 11th transaction sent from the sender's address."
                    placement="top"
                    trigger="hover"
                    width="250"
                  >
                  </el-popover>
                  <el-popover
                    popper-class="new-ele-popper"
                    content="Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be the 11th transaction sent from the sender&#39;s address."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Nonce:

                  <!-- <el-tooltip
                          class="item"
                          effect="dark"
                          content="Index position of Transaction in the block"
                          placement="top"
                          width="200"
                        >
                          <span
                            id="ContentPlaceHolder1_spanNonce"
                            class="
                              u-label
                              u-label--xs
                              u-label--badge-out
                              u-label--secondary
                              ml-1
                            "
                            >Position</span
                          >
                        </el-tooltip> -->
                </div>
                <div class="col-md-9">
                  <span
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Transaction Nonce"
                    >{{ txpendingInfo.nonce }}</span
                  >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Index position of Transaction in the block"
                    placement="top"
                    width="200"
                  >
                    <!-- <span
                      data-toggle="tooltip"
                      title=""
                      class="
                        u-label
                        u-label--xs
                        u-label--badge-out
                        u-label--secondary
                        ml-1
                        mr-3
                      "
                      >{{ txpendingInfo.position }}</span -->
                    >
                  </el-tooltip>
                </div>
              </div>
              <hr class="hr-space" />

              <div class="row">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <el-popover
                    popper-class="new-ele-popper"
                    content="Additional information that is required for the transaction."
                    placement="top"
                    width="260"
                    trigger="hover"
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-sm-inline-block
                        mr-1
                      "
                      slot="reference"
                    ></i> </el-popover
                  >Input Data:
                </div>
                <div class="col-md-9">
                  <div id="rawtab" data-target-group="inputDataGroup">
                    <span
                      data-toggle="tooltip"
                      title=""
                      data-original-title="The binary data that formed the input to the transaction, either the input data if it was a message call or the contract initialisation if it was a contract creation"
                    >
                      <textarea
                        v-model="txpendingInfo.input"
                        readonly=""
                        spellcheck="false"
                        class="
                          form-control
                          bg-light
                          text-secondary text-monospace
                          p-3
                        "
                        rows="2"
                        id="inputdata"
                      ></textarea
                      ><span id="rawinput" style="display: none">0x</span>
                    </span>
                  </div>
                  <div
                    id="decodetab"
                    data-target-group="inputDataGroup"
                    style="display: none; opacity: 0"
                  >
                    <span id="loadingtxframe">
                      <div
                        id="overlayMain"
                        align="center"
                        style="display: block"
                      >
                        <img
                          src="/images/main/loadingblock.svg"
                          alt="Loading"
                        />
                      </div>
                    </span>
                    <div id="decodebox" style="display: none">
                      <div
                        class="
                          js-scrollbar
                          scrollbox
                          mCustomScrollbar
                          _mCS_1
                          mCS-autoHide mCS_no_scrollbar
                        "
                        style="position: relative; overflow: visible"
                      >
                        <div
                          id="mCSB_1"
                          class="
                            mCustomScrollBox
                            mCS-minimal-dark
                            mCSB_vertical mCSB_outside
                          "
                          style="max-height: none"
                          tabindex="0"
                        >
                          <div
                            id="mCSB_1_container"
                            class="
                              mCSB_container
                              mCS_y_hidden mCS_no_scrollbar_y
                            "
                            style="position: relative; top: 0; left: 0"
                            dir="ltr"
                          >
                            <div
                              id="inputDecode"
                              class="bg-light table-responsive"
                            ></div>
                          </div>
                        </div>
                        <div
                          id="mCSB_1_scrollbar_vertical"
                          class="
                            mCSB_scrollTools mCSB_1_scrollbar
                            mCS-minimal-dark
                            mCSB_scrollTools_vertical
                          "
                          style="display: none"
                        >
                          <div class="mCSB_draggerContainer">
                            <div
                              id="mCSB_1_dragger_vertical"
                              class="mCSB_dragger"
                              style="
                                position: absolute;
                                min-height: 50px;
                                top: 0px;
                              "
                            >
                              <div
                                class="mCSB_dragger_bar"
                                style="line-height: 50px"
                              ></div>
                            </div>
                            <div class="mCSB_draggerRail"></div>
                          </div>
                        </div>
                      </div>
                      <a
                        href="javascript:;"
                        class="js-animation-link btn btn-xss btn-secondary mt-2"
                        onclick="javascript:btnSwitchClick();"
                        ><i class="fa fa-undo txhash-icon"></i> Switch Back</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div id="ContentPlaceHolder1_privatenotediv"></div>
            </div>
          </div>
        </div>
      </main>
      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <comfooter></comfooter>
  </div>
</template>

<script>
import comheader from "@/components/serach_header";
import comfooter from "@/components/comfooter";
import { get_node_info, get_transaction_by_hash } from "@/API/api";
import Web3 from "web3";

export default {
  name: "txspendingdetail",
  data() {
    return {
      status: true,
      isSuccess_copy1: false,
      isSuccess_copy2: false,
      isSuccess_copy3: false,
      timer: null,
      txpendingInfo: {
        hash: "",
        status: "",
        from: "",
        to: "",
        blockNumber: "",
        gasUsed: "0",
        type: "",
        value: "",
        nonce: "",
        position: "",
        input: "",
        gasprice: "",
        gas: "",
      },
      nodeData: {}, //获取节点信息
      isContract: 0,
    };
  },
  components: {
    comheader,
    comfooter,
  },
  mounted() {
    let that = this;
    that.txpendingInfo.hash = that.$route.params.hash;
    that.getNodeData();
  },
  filters: {
    fromWei(value) {
      if (value == null) {
        return 0;
      }

      var web3 = new Web3();
      var value = web3.utils.fromWei(value.toString(), "ether");
      return value;
    },
    str_Address(address) {
      let str1 = address.slice(0, 4);
      let str2 = address.slice(-4);

      return str1 + "..." + str2;
    },
    timeFromat(date) {
      return timeFrom(date);
    },
    dateFromat(date) {
      return new Date(parseInt(date) * 1000).toUTCString();
    },
    valueFormat(data) {
      var res = data / Math.pow(10, 8);
      return res;
    },
  },
  methods: {
    async getNodeData() {
      let that = this;
      get_node_info().then((res) => {
        that.nodeData = res;

        var web3 = new Web3(
          new Web3.providers.HttpProvider(that.nodeData.node_url)
        );
        web3.eth.getTransactionReceipt(that.$route.params.hash).then((res) => {
          if (res != null) {
            that.txpendingInfo.status = res.status;
            that.txpendingInfo.gasUsed = res.gasUsed;
            
            
          }
        });

        web3.eth.getTransaction(that.$route.params.hash).then((res) => {
          if (res != null) {
    
            that.txpendingInfo.blockNumber = res.blockNumber;
            that.txpendingInfo.value = res.value;
            that.txpendingInfo.from = res.from;
            that.txpendingInfo.to = res.to;
            that.txpendingInfo.nonce = res.nonce;
            that.txpendingInfo.input = res.input;
            that.txpendingInfo.gasprice = res.gasPrice;
            that.txpendingInfo.type = res.type;
            that.ifContract(that.txpendingInfo.to);
          }
        });

      });
    },

    onCopy1: function (e) {
      let that = this;

      that.isSuccess_copy1 = true;
      that.timer = setTimeout(function () {
        that.isSuccess_copy1 = false;
      }, 2000);
    },

    onCopy2: function (e) {
      let that = this;

      that.isSuccess_copy2 = true;
      that.timer = setTimeout(function () {
        that.isSuccess_copy2 = false;
      }, 2000);
    },
    onCopy3: function (e) {
      let that = this;

      that.isSuccess_copy3 = true;
      that.timer = setTimeout(function () {
        that.isSuccess_copy3 = false;
      }, 2000);
    },
    onError: function (e) {
      let that = this;
      this.$message({
        showClose: true,
        message: "复制失败",
        type: "warning",
      });
    },
    ifContract(addr) {
      let that = this;
      get_node_info().then((res) => {
        that.nodeData = res;
        var web3 = new Web3(
          new Web3.providers.HttpProvider(that.nodeData.node_url)
        );
        web3.eth.getCode(addr).then((data) => {
          if (data == "0x") {
            that.isContract = 0;
          } else {
            that.isContract = 1;
          }
        });
      });
    },
  },
};
</script>
