<template>
  <div>
    <serachheader :index="2"></serachheader>
    <div class="wrapper">
      <main id="content" role="main">
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age" />
        <input
          type="hidden"
          name="hdnDateTimeText"
          id="hdnDateTimeText"
          value="Date Time (UTC)"
        />
        <input
          type="hidden"
          name="hdnAgeTitle"
          id="hdnAgeTitle"
          value="Click to show Age Format"
        />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          value="Click to show Datetime Format"
        />
        <input
          type="hidden"
          name="hdnTxnText"
          id="hdnTxnText"
          value="Txn Fee"
        />
        <input
          type="hidden"
          name="hdnGasPriceText"
          id="hdnGasPriceText"
          value="Gas Price"
        />
        <input
          type="hidden"
          name="hdnTxnFeeTitle"
          id="hdnTxnFeeTitle"
          value="(Gas Price * Gas Used by Txns) in Ether"
        />
        <input
          type="hidden"
          name="hdnGasPriceTitle"
          id="hdnGasPriceTitle"
          value="Gas Price in Gwei"
        />
        <div class="container">
          <div
            class="d-md-flex justify-content-between align-items-center py-3"
          >
            <div class="mb-1 mb-md-0">
              <h1 class="h4 font-weight-normal mb-0">
                Blocks&nbsp;<span class="small text-secondary"></span>
                <p class="mb-0 text-break small">
                  <span class="small text-secondary"></span>
                </p>
              </h1>
            </div>
          </div>
        </div>

        <div class="container space-bottom-2">
          <div class="card">
            <div class="card-body">
              <div
                id="ContentPlaceHolder1_topPageDiv"
                class="d-md-flex justify-content-between mb-4"
              >
                <p class="mb-2 mb-md-0">
                  <i
                    id="spinwheel"
                    class="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                    style="display: none"
                  ></i>
                  Block #{{ endBlock.height }} to #{{
                    firstBlock.height
                  }}
                  (Total of {{ totalBlocks }} blocks)
                </p>
                <nav aria-label="page navigation">
                  <ul class="pagination pagination-sm mb-0">
                    <li class="page-item" :class="{ disabled: page == 0 }">
                      <span class="page-link" @click="page = 0;changePage()">First</span>
                    </li>
                    <li class="page-item" :class="{ disabled: page == 0 }">
                      <span  @click="page = page -1;changePage()" class="page-link"
                        ><i class="fa fa-chevron-left small"></i></span
                      ><span class="sr-only">Previous</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link text-nowrap"
                        >Page
                        <strong class="font-weight-medium">{{
                          page + 1
                        }}</strong>
                        of
                        <strong class="font-weight-medium">{{
                          allPages
                        }}</strong></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: (page + 1) == allPages }"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Go to Next"
                    >
                      <span
                        class="page-link"
                        @click="page = page+1;changePage()"
                        aria-label="Next"
                        ><span aria-hidden="True"
                          ><i class="fa fa-chevron-right small"></i
                        ></span>
                        <span class="sr-only">Next</span></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: page + 1 == allPages }"
                    >
                      <span
                        @click="page = allPages-1;changePage()"
                        class="page-link"
                        ><span aria-hidden="True">Last</span>
                        <span class="sr-only">Last</span></span
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="table-responsive mb-2 mb-md-0">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Block</th>
                      <!-- <th scope="col">
                        <a
                          href="javascript:;"
                          onclick="switchAgeToDateTime(this)"
                          id="lnkAgeDateTime"
                          title=""
                          data-toggle-commonjs="tooltip"
                          data-original-title="Click to show Datetime Format"
                          >Age</a
                        >
                      </th> -->
                      <th scope="col">Age</th>
                      <th scope="col">Txn</th>
                      <!-- <th scope="col">Uncles</th> -->
                      <th scope="col" v-if="false">Miner</th>
                      <th scope="col">Gas Used</th>
                      <th scope="col">Gas Limit</th>
                      <th scope="col">Base Fee</th>
                      <th scope="col">Reward</th>
                      <th
                        scope="col"
                        title="Percentage of FeesBurnt/TxFees Earned (excluding base block reward)"
                      >
                        Burnt Fees ({{ nodeData.code }})
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in blockList" :key="index">
                      <td>
                        <a :href="'/blocksdetail/'+ item.height">{{ item.height }}</a>
                      </td>

                      <td style="" class="showAge">
                        <span
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                          data-original-title=""
                          >{{ item.created_at | timeFromat }}</span
                        >
                      </td>
                      <td>
                        <a :href="'/txs?height=' + item.height">{{
                          item.tx_size
                        }}</a>
                      </td>
                      <!-- <td>0</td> -->
                      <td v-if="false">
                        <a
                          :href="'/txs?address=' + item.miner_address"
                          class="hash-tag text-truncate"
                          data-boundary="viewport"
                          data-toggle="tooltip"
                          title=""
                          data-original-title=""
                          >{{ item.miner_address }}</a
                        >
                      </td>
                      <td>
                        0
                        <span class="small text-secondary"
                          >(<span
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Gas Used %"
                            >0.00%</span
                          >,
                          <span
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Gas Target %"
                            >0.00%</span
                          >)</span
                        >
                        <div class="progress mt-1" style="height: 2px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 0%"
                            aria-valuenow="23.88"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>0.00</td>
                      <td>0 Gwei</td>
                      <td>0.00 {{ nodeData.code }}</td>
                      <td>
                        0.00
                        <span class="small text-secondary">(0.00%)</span>
                        <div class="progress mt-1" style="height: 2px">
                          <div
                            class="progress-bar bg-warning"
                            role="progressbar"
                            style="width: 0%"
                            aria-valuenow="82.71"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="ContentPlaceHolder1_pageRecords">
                <form
                  method="post"
                  action="https://etherscan.io/blocks"
                  id="ctl00"
                >
                  <div class="aspNetHidden">
                    <input
                      type="hidden"
                      name="__EVENTTARGET"
                      id="__EVENTTARGET"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__EVENTARGUMENT"
                      id="__EVENTARGUMENT"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__LASTFOCUS"
                      id="__LASTFOCUS"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__VIEWSTATE"
                      id="__VIEWSTATE"
                      value="NoLdJJEuXPYcjNNUgk92gK9kBxIu8AXRfwEELHl3iDlZ/Op8J0JWaoGux2qvy1Jq17cTaZI60exBIPA1SfW7zR8F0wpwHe5Q0N6qFe7GWCQ="
                    />
                  </div>

                  <div class="aspNetHidden">
                    <input
                      type="hidden"
                      name="__VIEWSTATEGENERATOR"
                      id="__VIEWSTATEGENERATOR"
                      value="1E110816"
                    />
                    <input
                      type="hidden"
                      name="__EVENTVALIDATION"
                      id="__EVENTVALIDATION"
                      value="NN0+1Wu/Viub7SsD8A65Y2vSrQBNbddF+/XSi41Qgvw0JMMl4FkWbAmA6zMYohFhAPg3RuZcaA/SNY3Dby8XZ4hLj94WwaI4TVv1804k5ISMA5ZFT21ypqC6bGtmX8zXkewFgwVDiJ/p3kh0k1KYnXs9jFjLAJEWknSSY2QbG/mD6JLHuskCsSdwUG+dd7zAWAZ7sf+beIhVgwPWNhrdhQ=="
                    />
                  </div>
                  <div class="d-md-flex justify-content-between my-3">
                    <div
                      class="
                        d-flex
                        align-items-center
                        text-secondary
                        mb-2 mb-md-0
                      "
                    >
                      Show
                      <select
                        name="ctl00$ContentPlaceHolder1$ddlRecordsPerPage"
                        v-model="per_page"
                        @change="changePerPage"
                        id="ContentPlaceHolder1_ddlRecordsPerPage"
                        class="custom-select custom-select-xs mx-2"
                      >
                        <option value="10">10</option>
                        <option selected="selected" value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Records
                    </div>
                      <nav class="mb-4 mb-md-0" aria-label="page navigation">
                  <ul class="pagination pagination-sm mb-0">
                    <li class="page-item" :class="{ disabled: page == 0 }">
                      <span class="page-link" @click="page = 0;changePage()">First</span>
                    </li>
                    <li class="page-item" :class="{ disabled: page == 0 }">
                      <span  @click="page = page -1;changePage()" class="page-link"
                        ><i class="fa fa-chevron-left small"></i></span
                      ><span class="sr-only">Previous</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link text-nowrap"
                        >Page
                        <strong class="font-weight-medium">{{
                          page + 1
                        }}</strong>
                        of
                        <strong class="font-weight-medium">{{
                          allPages
                        }}</strong></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: (page + 1) == allPages }"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Go to Next"
                    >
                      <span
                        class="page-link"
                        @click="page = page+1;changePage()"
                        aria-label="Next"
                        ><span aria-hidden="True"
                          ><i class="fa fa-chevron-right small"></i
                        ></span>
                        <span class="sr-only">Next</span></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: page + 1 == allPages }"
                    >
                      <span
                        @click="page = allPages-1;changePage()"
                        class="page-link"
                        ><span aria-hidden="True">Last</span>
                        <span class="sr-only">Last</span></span
                      >
                    </li>
                  </ul>
                </nav>
                    
                  </div>
                </form>
              </div>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  mt-3
                  mb-4
                "
              >
                <div class="d-flex align-items-center text-secondary"></div>
                <div class="small text-muted"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="push"></div>
        <div
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          id="emailSubscribeModalBox"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <button
                  type="button"
                  class="close close-md"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div class="text-center py-5-alt px-4">
                  <div id="emailSubscribeModalBoxText"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <comfooter></comfooter>
    <a
      class="js-go-to u-go-to animated"
      href="https://etherscan.io/blocks#"
      data-position='{"bottom": 20, "right": 15 }'
      data-type="fixed"
      data-offset-top="400"
      data-compensation="#header"
      data-show-effect="slideInUp"
      data-hide-effect="slideOutDown"
      style="
        display: none;
        position: fixed;
        bottom: 20px;
        right: 15px;
        opacity: 0;
      "
    >
      <span class="fa fa-arrow-up u-go-to__inner"></span>
    </a>
  </div>
</template>
<script>
import comfooter from "@/components/comfooter";
import serachheader from "@/components/serach_header.vue";
import { get_block_list, get_node_info } from "@/API/api";
import { timeFrom } from "@/Utils/timeFrom";

export default {
  name: "block",

  data() {
    return {
      nodeData: {}, //获取节点信息
      page: 0,
      per_page: 25,
      blockList: [],
      totalBlocks: 0,
      allPages: 1,
      firstBlock: {},
      endBlock: {},
    };
  },
  components: {
    comfooter,
    serachheader,
  },
  filters: {
    str_Address(address) {
      let str1 = address.slice(0, 4);
      let str2 = address.slice(-4);

      return str1 + "..." + str2;
    },
    timeFromat(date) {
      return timeFrom(date);
    },
  },
  mounted() {
    let that = this;
    that.getNodeData();
    that.get_block_list();
  },
  methods: {
    changePerPage() {
      let that = this;
      that.page = 0;
      that.get_block_list();
    },
    changePage() {
      let that = this;
      that.get_block_list();
    },
    getNodeData() {
      let that = this;
      get_node_info().then((res) => {
        that.nodeData = res;
      });
    },
    get_block_list() {
      let that = this;
      get_block_list(that.per_page, that.page).then((res) => {

        that.blockList = res.data.content;
        that.totalBlocks = res.data.totalElements;
        that.allPages = res.data.totalPages;
        if (that.blockList.length > 0) {
          that.firstBlock = that.blockList[0];
          that.endBlock = that.blockList[that.blockList.length - 1];
        }
      });
    },
 
  },
};
</script>

