let prc20tokens = [
  {
    address: "0x9a00EbF48666227e87bf2E1951823666CD3D474e",
    createdAt: 1657074269,
    creator: "0x5f1d3c2f62bf5374cec48f05cb6b6c8a2ab604f8",
    decimal: 18,
    height: 2654411,
    info: null,
    kind: 1,
    name: "USDT",
    pic: null,
    status: 1,
    totalSupply: "0",
    txHash:
      "0xbe67b685cfd2c6c3afc6fbeee5f52a4e9902f609f8b497c2ca0ac6b54f5bc57e",
  },
  {
    address: "0xF4C9ad4cf3595ddaCDe89B6dE8d7cf8d948dA9DB",
    createdAt: 1657074269,
    creator: "0xfcbd756a7f6a16e5c62b2aebe306604edbef731c",
    decimal: 18,
    height: 2653914,
    info: null,
    kind: 0,
    name: "Wrapped Psc",
    pic: null,
    status: 1,
    totalSupply: "100000000000000",
    txHash:
      "0x158afd53ca820b3693f7120a0bc19f6c06c6e9784dd96eda61f453c4389fe190",
  },
  {
    address: "0xaC5d08999371F54a4c14f73941566160ce5D6460",
    createdAt: 1657074269,
    creator: "0x5f1d3c2f62bf5374cec48f05cb6b6c8a2ab604f8",
    decimal: 18,
    height: 2654419,
    info: null,
    kind: 0,
    name: "WDC",
    pic: null,
    status: 1,
    totalSupply: "590000000",
    txHash:
      "0x5fcf3f7da9f7ed2b9daa62a2067db0c772d06663d8470b241131e3c2895e8a0f",
  },
  {
    address: "0xE6e67fc4e00AcAe886Bd17eFB78d547c434a75B5",
    createdAt: 1718880803,
    creator: "0x5f1d3c2f62bf5374cec48f05cb6b6c8a2ab604f8",
    decimal: 18,
    height: 20036770,
    info: null,
    kind: 0,
    name: "CAT",
    pic: null,
    status: 1,
    totalSupply: "1000000000",
    txHash:
      "0x36c40566630bf4d97e73868b9b1f20fe26444390254c56a0a95bcaccaf3d154f",
  },
];

export { prc20tokens };
