<template>
  <div>
    <div class="wrapper">
      <comheader :index="2"></comheader>
      <main id="content" role="main">
        <div class="container">
          <div
            class="d-md-flex justify-content-between align-items-center py-3"
          >
            <div class="mb-1 mb-md-0">
              <h1 class="h4 font-weight-normal mb-0">
                Top Accounts by PSC Balance&nbsp;<span
                  class="small text-secondary"
                ></span>
              </h1>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="5igjfxwqz7 py-3">
            <div class="d-flex text-secondary" style="line-height: 2.2">
              <span id="ContentPlaceHolder1_lblAdResult"
                ><ins
                  data-revive-zoneid="2"
                  data-revive-id="6452186c83cd256052c3c100f524ed97"
                  data-revive-seq="0"
                  id="revive-0-0"
                  data-revive-loaded="1"
                  style="text-decoration: none"
                  ><b>Featured:</b> Curious on PolySmartChain's hottest 🔥
                  trading pairs? View top pairs and details with
                  <a
                    :href="dexwebsite"
                    target="_blank"
                    title="Links to an External Advertiser site"
                  >
                    <b>DEX Trading Pairs!</b></a
                  >
                  <div
                    id="beacon_93d561a964"
                    style="
                      position: absolute;
                      left: 0px;
                      top: 0px;
                      visibility: hidden;
                    "
                  >
                    <img
                      src="https://kta.etherscan.com/www/d/lg.php?ebannerid=1681&amp;campaignid=72&amp;zoneid=2&amp;loc=https%3A%2F%2Fetherscan.io%2Faccounts%2F1&amp;referer=https%3A%2F%2Fetherscan.io%2Faccounts%2F2&amp;cb=93d561a964"
                      width="0"
                      height="0"
                      alt=""
                      style="width: 0px; height: 0px"
                    /></div
                ></ins> </span
              >&nbsp;
            </div>
          </div>
        </div>

        <div class="container space-bottom-2">
          <div class="card">
            <div class="card-body">
              <div
                id="ContentPlaceHolder1_topPageDiv"
                class="d-md-flex justify-content-between mb-4"
              >
                <p class="mb-2 mb-md-0">
                  <i
                    id="spinwheel"
                    class="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                    style="display: none"
                  ></i>
                  A total of {{ totalTokens }} address found<span
                    class="d-block text-secondary small"
                    >(Showing the last 1,000 top accounts only)</span
                  >
                </p>
                <nav aria-label="page navigation">
                  <ul class="pagination pagination-sm mb-0">
                    <li
                      class="page-item"
                      :class="{ disabled: page == 0 || !clickFlag }"
                    >
                      <span
                        class="page-link"
                        @click="
                          page = 0;
                          changePage();
                        "
                        >First</span
                      >
                    </li>

                    <li
                      class="page-item"
                      :class="{ disabled: page == 0 || !clickFlag }"
                    >
                      <span
                        @click="
                          page = page - 1;
                          changePage();
                        "
                        class="page-link"
                        ><i class="fa fa-chevron-left small"></i></span
                      ><span class="sr-only">Previous</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link text-nowrap"
                        >Page
                        <strong class="font-weight-medium">{{
                          page + 1
                        }}</strong>
                        of
                        <strong class="font-weight-medium">{{
                          allPages
                        }}</strong></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: page + 1 == allPages || !clickFlag }"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Go to Next"
                    >
                      <span
                        class="page-link"
                        @click="
                          page = page + 1;
                          changePage();
                        "
                        aria-label="Next"
                        ><span aria-hidden="True"
                          ><i class="fa fa-chevron-right small"></i
                        ></span>
                        <span class="sr-only">Next</span></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: page + 1 == allPages || !clickFlag }"
                    >
                      <span
                        @click="
                          page = allPages - 1;
                          changePage();
                        "
                        class="page-link"
                        ><span aria-hidden="True">Last</span>
                        <span class="sr-only">Last</span></span
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <div
                id="ContentPlaceHolder1_divTable"
                class="table-responsive mb-2 mb-md-0"
              >
                <table class="table table-hover" style="width: 100%">
                  <thead class="thead-light">
                    <tr>
                      <th
                        id="ContentPlaceHolder1_headerRank"
                        width="40"
                        scope="col"
                      >
                        Rank
                      </th>
                      <th scope="col" width="500">Address</th>
                      <!-- <th class="remove-icon" scope="col">Name Tag</th> -->
                      <th scope="col">
                        <i class="fa fa-angle-down text-secondary"></i> Balance
                      </th>
                      <!-- <th id="ContentPlaceHolder1_headerPercent" scope="col">
                        Percentage
                      </th> -->
                      <!-- <th scope="col">Txn Count</th> -->
                      <th scope="col" width="250">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in list" :key="index">
                      <td>{{ item.order }}</td>
                      <td>
                        <span style="white-space: nowrap">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="contract"
                            placement="top"
                          >
                            <i
                              class="far fa-file-text-o text-secondary"
                              data-toggle="tooltip"
                              data-boundary="viewport"
                              data-html="true"
                              title=""
                              data-original-title="Contract"
                              v-if="item.is_contract"
                            ></i></el-tooltip
                          ><a :href="'/txs?address=' + item.address">{{
                            item.name == "" ? item.address : item.name
                          }}</a></span
                        >
                      </td>
                      <!-- <td>{{}}</td>
                      <td>{{}}</td> -->
                      <td>{{ item.balance }}</td>
                      <td>{{ item.price }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="ContentPlaceHolder1_divBottomPagination">
                <form method="post" action="./tokentxns" id="ctl00">
                  <div class="aspNetHidden">
                    <input
                      type="hidden"
                      name="__EVENTTARGET"
                      id="__EVENTTARGET"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__EVENTARGUMENT"
                      id="__EVENTARGUMENT"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__LASTFOCUS"
                      id="__LASTFOCUS"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__VIEWSTATE"
                      id="__VIEWSTATE"
                      value="s0dyCnOq1ss75G+J7V1dK170Y7TLBxwi3G2XwhODywxQ3k/fUBPFsE1f6rLLfKdx4ZppPDFP67guHjyHFq/Qxa1vA1iDjW64nsLTdqgKCu0="
                    />
                  </div>
                  <div class="aspNetHidden">
                    <input
                      type="hidden"
                      name="__VIEWSTATEGENERATOR"
                      id="__VIEWSTATEGENERATOR"
                      value="CBF7936C"
                    />
                    <input
                      type="hidden"
                      name="__EVENTVALIDATION"
                      id="__EVENTVALIDATION"
                      value="2orRN+JJ0FN7H8hfqP+mx6tVJciGe9x/B+jskyLdnP65Qd/blSm7Pf7CrHcQsj8QT5PqvknTWxQ+Q4KR2lH7dMJ0zsNkWe6NlRGMvAe5JiJNFxm00+QjHd+d9YMnJi7gT4pf98Ct1fZqX/gzIv5HNztI3BhQCCJ7mDDaLg2dJP7PynjGCb41wWcIpSmQqJbdwWoVtTYstPhsOHEG3DemOQ=="
                    />
                  </div>
                  <div class="d-md-flex justify-content-between my-3">
                    <div
                      id="ContentPlaceHolder1_pageRecords"
                      class="
                        d-flex
                        align-items-center
                        text-secondary
                        mb-2 mb-md-0
                      "
                    >
                      Show
                      <select
                        name="ctl00$ContentPlaceHolder1$ddlRecordsPerPage"
                        v-model="per_page"
                        @change="changePerPage"
                        id="ContentPlaceHolder1_ddlRecordsPerPage"
                        class="custom-select custom-select-xs mx-2"
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected="selected" value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Records
                    </div>
                    <nav class="mb-4 mb-md-0" aria-label="page navigation">
                      <ul class="pagination pagination-sm mb-0">
                        <li
                          class="page-item"
                          :class="{ disabled: page == 0 || !clickFlag }"
                        >
                          <span
                            class="page-link"
                            @click="
                              page = 0;
                              changePage();
                            "
                            >First</span
                          >
                        </li>

                        <li
                          class="page-item"
                          :class="{ disabled: page == 0 || !clickFlag }"
                        >
                          <span
                            @click="
                              page = page - 1;
                              changePage();
                            "
                            class="page-link"
                            ><i class="fa fa-chevron-left small"></i></span
                          ><span class="sr-only">Previous</span>
                        </li>
                        <li class="page-item">
                          <span class="page-link text-nowrap"
                            >Page
                            <strong class="font-weight-medium">{{
                              page + 1
                            }}</strong>
                            of
                            <strong class="font-weight-medium">{{
                              allPages
                            }}</strong></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{
                            disabled: page + 1 == allPages || !clickFlag,
                          }"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Go to Next"
                        >
                          <span
                            class="page-link"
                            @click="
                              page = page + 1;
                              changePage();
                            "
                            aria-label="Next"
                            ><span aria-hidden="True"
                              ><i class="fa fa-chevron-right small"></i
                            ></span>
                            <span class="sr-only">Next</span></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{
                            disabled: page + 1 == allPages || !clickFlag,
                          }"
                        >
                          <span
                            @click="
                              page = allPages - 1;
                              changePage();
                            "
                            class="page-link"
                            ><span aria-hidden="True">Last</span>
                            <span class="sr-only">Last</span></span
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <comfooter></comfooter>
  </div>
</template>

<script>
import comheader from "@/components/serach_header";
import comfooter from "@/components/comfooter";
import addr from "@/contract/address.json";
import { get_psc_list } from "@/API/api";
export default {
  data() {
    return {
      page: 0,
      per_page: 25,
      allPages: 0,
      totalTokens: 0,
      list: [],
      dexwebsite: addr.dexwebsite,
      clickFlag: true,
    };
  },
  async mounted() {
    let that = this;
    that.getPscList();
  },
  components: {
    comheader,
    comfooter,
  },
  methods: {
    async getPscList() {
      let that = this;
      await get_psc_list(that.per_page, that.page).then((res) => {
        that.list = res.data.content;
        that.totalTokens = res.data.totalElements;
        that.allPages = Math.ceil(1000 / Number(that.per_page));
      });
    },
    async changePage() {
      let that = this;
      that.clickFlag = false;
      await that.getPscList();
      that.clickFlag = true;
    },
    async changePerPage() {
      let that = this;
      that.clickFlag = false;
      that.page = 0;
      that.getPscList();
      that.clickFlag = true;
    },
  },
};
</script>

<style lang='less' scoped>
</style>