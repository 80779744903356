<template>
  <div>
    <serachheader :index="2"></serachheader>
    <div class="wrapper">
      <main id="content" role="main">
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age" />
        <input
          type="hidden"
          name="hdnDateTimeText"
          id="hdnDateTimeText"
          value="Date Time (UTC)"
        />
        <input
          type="hidden"
          name="hdnAgeTitle"
          id="hdnAgeTitle"
          value="Click to show Age Format"
        />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          value="Click to show Datetime Format"
        />
        <input
          type="hidden"
          name="hdnTxnText"
          id="hdnTxnText"
          value="Txn Fee"
        />
        <input
          type="hidden"
          name="hdnGasPriceText"
          id="hdnGasPriceText"
          value="Gas Price"
        />
        <input
          type="hidden"
          name="hdnTxnFeeTitle"
          id="hdnTxnFeeTitle"
          value="(Gas Price * Gas Used by Txns) in Ether"
        />
        <input
          type="hidden"
          name="hdnGasPriceTitle"
          id="hdnGasPriceTitle"
          value="Gas Price in Gwei"
        />
        <div class="container py-3">
          <div class="d-sm-flex align-items-center">
            <div class="mb-2 mb-sm-0">
              <h1 class="h4 mb-0">
                Block
                <span class="small text-secondary"
                  >&nbsp;#{{ blockInfo.height }}</span
                >
              </h1>
            </div>
          </div>
        </div>
        <div class="container space-bottom-2">
          <div class="card">
            <div
              class="
                card-header
                d-flex
                justify-content-between
                align-items-center
                p-0
              "
            >
              <ul
                class="nav nav-custom nav-borderless nav_tabs1"
                id="nav_tabs"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    href=""
                    data-toggle="tab"
                    onclick=""
                    >Overview</a
                  >
                </li>
              </ul>
            </div>
            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                id="overview"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div id="ContentPlaceHolder1_maintable" class="card-body">
                  <div class="row align-items-center mt-1">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Block Height:
                    </div>
                    <div class="col-md-9">
                      <div class="d-flex">
                        <span class="font-weight-sm-bold mr-2">{{
                          blockInfo.height
                        }}</span>
                        <a
                          class="btn btn-xs btn-icon btn-soft-info mr-1"
                          :href="'/blocksdetail/' + (blockInfo.height - 1)"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="View previous block"
                          ><i class="fa fa-chevron-left btn-icon__inner"></i
                        ></a>
                        <a
                          class="btn btn-xs btn-icon btn-soft-info mr-1"
                          :href="'/blocksdetail/' + (blockInfo.height + 1)"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="View next block"
                          v-if="blockInfo.height < latestBlock"
                          ><i class="fa fa-chevron-right btn-icon__inner"></i
                        ></a>
                        <a
                          class="btn btn-xs btn-icon btn-soft-info mr-1"
                          href="#"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="View next block"
                          v-if="blockInfo.height >= latestBlock"
                          ><i class="fa fa-chevron-right btn-icon__inner"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <hr class="hr-space" />
                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The date and time at which a block is mined."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Timestamp:
                    </div>
                    <div class="col-md-9">
                      <i class="far fa-clock small mr-1"></i
                      >{{ blockInfo.created_at | timeFromat }} ({{
                        blockInfo.created_at | dateFromat
                      }})
                    </div>
                  </div>
                  <div id="ContentPlaceHolder1_div_tx_fieldname">
                    <hr class="hr-space mb-2" />
                    <div class="row align-items-center">
                      <div
                        class="
                          col-md-3
                          font-weight-bold font-weight-sm-normal
                          mb-1 mb-md-0e
                        "
                      >
                        <el-popover
                          popper-class="new-ele-popper"
                          content="The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether value."
                          placement="top"
                          width="260"
                          trigger="hover"
                        >
                          <i
                            class="
                              fal
                              fa-question-circle
                              text-secondary
                              d-sm-inline-block
                              mr-1
                            "
                            slot="reference"
                          ></i> </el-popover
                        >Transactions:
                      </div>
                      <div
                        id="ContentPlaceHolder1_div_tx_fieldvalue"
                        class="col-md-9"
                      >
                        <a
                          class="
                            u-label u-label--value u-label--primary
                            rounded
                            my-1
                          "
                          :href="'/txs?height=' + blockInfo.height"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Click to view Transactions"
                          >{{ blockInfo.transactions }} transactions</a
                        >
                      </div>
                    </div>
                  </div>
                  <hr class="hr-space mt-2" />
                  <div class="row align-items-center" v-if="false">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="Miner who successfully include the block onto the blockchain."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Mined by:
                    </div>
                    <div class="col-md-9">
                      {{ blockInfo.miner }}
                    </div>
                  </div>
                  <hr class="hr-space" v-if="false" />
                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="For each block, the miner is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Block Reward:
                    </div>
                    <div class="col-md-9">
                      0
                      <!-- 2<b>.</b>064256865725895886 Ether (<span
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="Static Block Reward"
                        >2</span
                      >
                      +
                      <span
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="Txn Fees"
                        >0.371557973266184803</span
                      ><span
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="Burnt Fees"
                      >
                        - 0.307301107540288917</span
                      >) -->
                    </div>
                  </div>
                  <hr class="hr-space" />
                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Difficulty:
                    </div>
                    <div class="col-md-9">{{ blockInfo.difficulty }}</div>
                  </div>
                  <hr class="hr-space" />
                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="Total difficulty of the chain until this block."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Total Difficulty:
                    </div>
                    <div class="col-md-9">0</div>
                  </div>
                  <hr class="hr-space" />
                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The block size is actually determined by the block&#39;s gas limit."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Size:
                    </div>
                    <div class="col-md-9">{{ blockInfo.size }} bytes</div>
                  </div>
                  <hr class="hr-space hr-gasTarget-top" />
                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The total gas used in the block and its percentage of gas filled in the block."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Gas Used:
                    </div>
                    <div class="col-md-9 d-flex align-items-center">0 (0%)</div>
                  </div>
                  <hr class="hr-space hr-gasTarget-bottom" />
                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="Total gas limit provided by all transactions in the block."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Gas Limit:
                    </div>
                    <div class="col-md-9">60000000</div>
                  </div>
                  <hr class="hr-space" />

                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="Any data that can be included by the miner in the block."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Extra Data:
                    </div>
                    <div class="col-md-9">{{ blockInfo.extra_data }}</div>
                  </div>
                  <hr class="hr-space" />
                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The hash of the block header of the current block."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >Hash:
                    </div>
                    <div class="col-md-9">
                      {{ blockInfo.hash }}
                    </div>
                  </div>
                  <hr class="hr-space" />

                  <div class="row align-items-center">
                    <div
                      class="
                        col-md-3
                        font-weight-bold font-weight-sm-normal
                        mb-1 mb-md-0e
                      "
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The root of the state trie"
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="
                            fal
                            fa-question-circle
                            text-secondary
                            d-sm-inline-block
                            mr-1
                          "
                          slot="reference"
                        ></i> </el-popover
                      >StateRoot:
                    </div>
                    <div class="col-md-9">
                      {{ blockInfo.state_root }}
                    </div>
                  </div>
                  <hr class="hr-space" />
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="comments"
                role="tabpanel"
                aria-labelledby="comments-tab"
              >
                <div class="card-body">
                  Make sure to use the "Vote Down" button for any spammy posts,
                  and the "Vote Up" for interesting conversations.<br />
                  <div id="disqus_thread"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <comfooter></comfooter>
  </div>
</template>

<script>
import { timeFrom } from "@/Utils/timeFrom";
import comfooter from "@/components/comfooter";
import serachheader from "@/components/serach_header";
import { get_block_by_height, get_node_info, get_block_list } from "@/API/api";
import Web3 from "web3";
export default {
  name: "blocksdetail",
  data() {
    return {
      blockInfo: {
        height: "",
        transactions: "",
        miner: "",
        difficulty: "",
        size: "",
        extra_data: "",
        hash: "",
        state_root: "",
        created_at: "",
      },
      latestBlock: 0,
      page: 0,
      per_page: 25,
      hrefstr: "'/blocksdetail/' + (blockInfo.height - 1)",
      flag: 0,
    };
  },
  components: {
    comfooter,
    serachheader,
  },
  mounted() {
    let that = this;
    that.loadHtml();
    that.getBlockByHeight(that.$route.params.height);
    that.getLatestBlock();
  },
  filters: {
    str_Address(address) {
      let str1 = address.slice(0, 4);
      let str2 = address.slice(-4);

      return str1 + "..." + str2;
    },
    timeFromat(date) {
      return timeFrom(date);
    },
    dateFromat(date) {
      return new Date(parseInt(date) * 1000).toUTCString();
    },
  },
  methods: {
    loadHtml() {
      var hash = window.location.hash.substring(1);
      $("[rel='tooltip']").tooltip({
        html: true,
      });
      if (hash != "") {
        activaTab(hash);
      }

      $(".collapse").on("shown.bs.collapse", function () {
        $("[data-toggle-dynamic=tooltip]").attr("title", "Click to see less");
      });

      $(".collapse").on("hidden.bs.collapse", function () {
        $("[data-toggle-dynamic=tooltip]").attr("title", "Click to see more");
      });

      $("#collapsedLink").on("mouseenter", function () {
        $("[data-toggle-dynamic=tooltip]").tooltip("show");
      });
      $("#collapsedLink").on("mouseleave", function () {
        $('[data-toggle-dynamic="tooltip"]').tooltip("dispose");
      });

      $("#ContentPlaceHolder1_collapsedLink_span").on("click", function () {
        if ($("#collapsePanel").css("display") == "none") {
          $("#collapsePanel").css("display", "block");
          $(".card-btn-arrow").css({
            "-webkit-transform": "rotate(0deg)",
            "-moz-transform": "rotate(0deg)",
          });
        } else {
          $("#collapsePanel").css("display", "none");
          $(".card-btn-arrow").css({
            "-webkit-transform": "rotate(-180deg)",
            "-moz-transform": "rotate(-180deg)",
          });
        }
      });
    },
    getBlockByHeight(height) {
      let that = this;
      get_block_by_height(height).then((res) => {

        that.blockInfo.height = res.data.height;
        that.blockInfo.transactions = res.data.body.length;
        that.blockInfo.miner = res.data.miner_address;
        that.blockInfo.difficulty = res.data.difficulty;
        that.blockInfo.size = res.data.size;
        that.blockInfo.extra_data = res.data.extra_data;
        that.blockInfo.hash = res.data.hash;
        that.blockInfo.state_root = res.data.state_root;
        that.blockInfo.created_at = res.data.created_at;
      });
    },
    getLatestBlock() {
      let that = this;

      get_block_list(that.per_page, that.page).then((res) => {

        that.latestBlock = res.data.content[0].height;
      });

    },
  },
};
</script>
