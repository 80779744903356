import Web3 from "web3";
import contractAddress from "@/contract/address";
let web3 = new Web3(contractAddress.rpc);
// var web3 = new Web3("http://208.83.238.70:8545");

// 传入事务哈希和全量abi接码出logs
async function decodeLog(txhash, abi) {
  console.log("开始解码");
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const receipt = await web3.eth.getTransactionReceipt(txhash);

      if (!receipt) {
        console.log("Transaction or receipt not found.");
        resolve(null);
      }

      let logs = [];

      receipt.logs.forEach((log) => {
        let eventAbi = getEventAbi(log.topics[0], abi).inputs;
        console.log(eventAbi);

        try {
          const decoded = web3.eth.abi.decodeLog(
            eventAbi,
            log.data,
            log.topics.slice(1)
          );
          logs = JSON.stringify(decoded, null, 4);
          resolve(logs);
        } catch (decodeError) {
          reject(decodeError);
          console.error("Error decoding log:", decodeError);
        }
      });
      if (logs.length == 0) {
        reject("Not log");
      }
    } catch (error) {
      console.error("Error fetching receipt:", error);
      reject(error);
    }
  });
}

// 根据事件签名获取事件 ABI
const getEventAbi = (eventSignature, abi) => {
  const eventAbi = abi.find((item) => {
    return (
      item.type === "event" &&
      web3.eth.abi.encodeEventSignature(item) === eventSignature
    );
  });
  if (!eventAbi) {
    throw new Error("Event ABI not found for signature: " + eventSignature);
  }
  return eventAbi;
};

export { decodeLog };
