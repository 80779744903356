<template>
  <div class="error--box">
    <p_header></p_header>

    <div class="error-404">
      <div>
        <p class="error--404-text">4</p>
        <p class="error--404-text">0</p>
        <p class="error--404-text">4</p>
      </div>
    </div>
    <div class="error-message">
      <h1 class="error--title">This page is lost.</h1>
      <p class="error--body-message">
        We've explored deep and wide,<br />
        but we can't find the page you were looking for.
      </p>
      <a href="/" class="btn-default btn-link-home">Navigate back home</a>
    </div>
  </div>
</template>
<script>
import p_header from "@/components/comheader.vue";
export default {
  data() {
    return {};
  },
  components: {
    p_header,
  },
};
</script>

<style scoped lang="less">
.error-404 {
  display: flex;
  margin: 0 auto;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 200px;
}
.error--box {
  padding-bottom: 50px;
  height: 100%;
  .btn-link-home {
    display: inline-block;
    padding: 10px 8px;
    width: 240px;
  }
}

.error--404-text {
  display: inline;
  font-weight: bold;
  color: rgb(229, 232, 235);
  /*width: 33.33%;*/
  font-size: 180px;
  margin-left: 20px;
  margin-right: 20px;
}
.error-message {
  text-align: center;
  padding-bottom: 100px;
  padding-left: 15%;
  padding-right: 15%;
  .error--title {
    font-weight: 600;
    font-size: 40px;
    letter-spacing: 0px;
    color: skyblue;
  }
  .error--body-message {
    color: rgb(112, 122, 131);
    font-size: 18px;
    padding: 30px 0;
  }
}
</style>