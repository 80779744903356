<template>
  <div>
    <div class="wrapper">
      <comheader :index="3"></comheader>
      <main id="content" role="main">
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age" />
        <input
          type="hidden"
          name="hdnDateTimeText"
          id="hdnDateTimeText"
          value="Date Time (UTC)"
        />
        <input
          type="hidden"
          name="hdnAgeTitle"
          id="hdnAgeTitle"
          value="Click to show Age Format"
        />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          value="Click to show Datetime Format"
        />
        <input
          type="hidden"
          name="hdnTxnText"
          id="hdnTxnText"
          value="Txn Fee"
        />
        <input
          type="hidden"
          name="hdnGasPriceText"
          id="hdnGasPriceText"
          value="Gas Price"
        />
        <input
          type="hidden"
          name="hdnTxnFeeTitle"
          id="hdnTxnFeeTitle"
          value="(Gas Price * Gas Used by Txns) in Ether"
        />
        <input
          type="hidden"
          name="hdnGasPriceTitle"
          id="hdnGasPriceTitle"
          value="Gas Price in Gwei"
        />
        <div class="bg-light py-3">
          <div class="container">
            <h1 class="h4 mb-0">
              <div class="d-flex align-items-center">Token Transfers</div>
              <p class="mb-0 text-break small">
                <span class="small text-secondary"></span>
              </p>
            </h1>
          </div>
        </div>

        <div class="container">
          <div class="border-top py-3">
            <div class="d-flex text-secondary" style="line-height: 2.2">
              <span id="ContentPlaceHolder1_lblAdResult"
                ><ins
                  data-revive-zoneid="2"
                  data-revive-id="94930f9d02164723d814eabe9168eb46"
                ></ins> </span
              >&nbsp;
            </div>
          </div>
        </div>
        <div class="container space-bottom-2">
          <div class="card">
            <div class="card-body">
              <div
                id="ContentPlaceHolder1_divTopPagination"
                class="d-md-flex justify-content-between mb-4"
              >
                <p class="mb-2 mb-md-0">
                  <i
                    id="spinwheel"
                    class="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                    style="display: none"
                  ></i>
                  A total of {{ totalTokens }} txns found
                  <span class="d-block text-secondary small"
                    >(Showing the last 10,000 records only)</span
                  >
                </p>
                <nav aria-label="page navigation">
                  <ul class="pagination pagination-sm mb-0">
                    <li class="page-item" :class="{ disabled: page == 0 }">
                      <span
                        class="page-link"
                        @click="
                          page = 0;
                          changePage();
                        "
                        >First</span
                      >
                    </li>

                    <li class="page-item" :class="{ disabled: page == 0 }">
                      <span
                        @click="
                          page = page - 1;
                          changePage();
                        "
                        class="page-link"
                        ><i class="fa fa-chevron-left small"></i></span
                      ><span class="sr-only">Previous</span>
                    </li>
                    <li class="page-item">
                      <span class="page-link text-nowrap"
                        >Page
                        <strong class="font-weight-medium">{{
                          page + 1
                        }}</strong>
                        of
                        <strong class="font-weight-medium">{{
                          allPages
                        }}</strong></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: page + 1 == allPages }"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Go to Next"
                    >
                      <span
                        class="page-link"
                        @click="
                          page = page + 1;
                          changePage();
                        "
                        aria-label="Next"
                        ><span aria-hidden="True"
                          ><i class="fa fa-chevron-right small"></i
                        ></span>
                        <span class="sr-only">Next</span></span
                      >
                    </li>
                    <li
                      class="page-item"
                      :class="{ disabled: page + 1 == allPages }"
                    >
                      <span
                        @click="
                          page = allPages - 1;
                          changePage();
                        "
                        class="page-link"
                        ><span aria-hidden="True">Last</span>
                        <span class="sr-only">Last</span></span
                      >
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="table-responsive mb-2 mb-md-0">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col" width="20"></th>
                      <th scope="col" width="1">Txn Hash</th>
                      <th scope="col">
                        <div class="d-flex align-items-center">
                          <div class="mr-2">Age</div>
                        </div>
                      </th>
                      <th scope="col">From</th>
                      <th scope="col" width="30"></th>
                      <th scope="col">To</th>
                      <th scope="col">Value</th>
                      <th scope="col">Token</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in transferList" :key="index">
                      <td>
                        <a
                          tabindex="0"
                          id="myFnExpandBox"
                          type="button"
                          class="
                            btn btn-xs btn-icon btn-soft-secondary
                            myFnExpandBox
                          "
                          :hash="item.hash"
                          :height="item.block_height"
                          data-container="body"
                          data-toggle="popover"
                          data-placement="right"
                          ><i class="far fa-eye btn-icon__inner"></i
                        ></a>
                      </td>
                      <td>
                        <span class="hash-tag text-truncate"
                          ><a
                            :href="'/tx/' + item.hash + '/' + item.block_height"
                            class="myFnExpandBox_searchVal"
                            >{{ item.hash }}</a
                          ></span
                        >
                      </td>
                      <td style="" class="showAge">
                        <span
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                          data-original-title=""
                          >{{ item.created_at | timeFromat }}</span
                        >
                      </td>
                      <td>
                        <a
                          class="hash-tag text-truncate"
                          :href="'/txs?address=' + item.from"
                          target="_parent"
                          data-toggle="tooltip"
                          :title="item.from"
                          >{{ item.from }}</a
                        >
                      </td>
                      <td class="text-center">
                        <span
                          class="
                            btn btn-xs btn-icon btn-soft-success
                            rounded-circle
                          "
                          ><i
                            class="fas fa-long-arrow-right btn-icon__inner"
                          ></i
                        ></span>
                      </td>
                      <td>
                        <i
                          class="far fa-file-alt text-secondary"
                          data-toggle="tooltip"
                          title="Contract"
                        ></i>
                        <span style="white-space: nowrap"
                          ><a
                            class="hash-tag text-truncate"
                            :href="'/txs?address=' + item.to"
                            target="_parent"
                            data-toggle="tooltip"
                            :title="item.to"
                            >{{ item.to }}</a
                          ></span
                        >
                      </td>
                      <td>{{ decimal(item.value, item.decimal) }}</td>
                      <td>
                        <a :href="'/txs?address=' + item.code">
                          <span data-toggle="tooltip" title="Wrapped Ether">
                            <font color="">{{ item.code_name }}</font>
                          </span>
                          ({{ item.code }})</a
                        >
                        <!-- {{ item.code_name }} ({{ item.code }}) -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="ContentPlaceHolder1_divBottomPagination">
                <form method="post" action="./tokentxns" id="ctl00">
                  <div class="aspNetHidden">
                    <input
                      type="hidden"
                      name="__EVENTTARGET"
                      id="__EVENTTARGET"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__EVENTARGUMENT"
                      id="__EVENTARGUMENT"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__LASTFOCUS"
                      id="__LASTFOCUS"
                      value=""
                    />
                    <input
                      type="hidden"
                      name="__VIEWSTATE"
                      id="__VIEWSTATE"
                      value="s0dyCnOq1ss75G+J7V1dK170Y7TLBxwi3G2XwhODywxQ3k/fUBPFsE1f6rLLfKdx4ZppPDFP67guHjyHFq/Qxa1vA1iDjW64nsLTdqgKCu0="
                    />
                  </div>
                  <div class="aspNetHidden">
                    <input
                      type="hidden"
                      name="__VIEWSTATEGENERATOR"
                      id="__VIEWSTATEGENERATOR"
                      value="CBF7936C"
                    />
                    <input
                      type="hidden"
                      name="__EVENTVALIDATION"
                      id="__EVENTVALIDATION"
                      value="2orRN+JJ0FN7H8hfqP+mx6tVJciGe9x/B+jskyLdnP65Qd/blSm7Pf7CrHcQsj8QT5PqvknTWxQ+Q4KR2lH7dMJ0zsNkWe6NlRGMvAe5JiJNFxm00+QjHd+d9YMnJi7gT4pf98Ct1fZqX/gzIv5HNztI3BhQCCJ7mDDaLg2dJP7PynjGCb41wWcIpSmQqJbdwWoVtTYstPhsOHEG3DemOQ=="
                    />
                  </div>
                  <div class="d-md-flex justify-content-between my-3">
                    <div
                      id="ContentPlaceHolder1_pageRecords"
                      class="
                        d-flex
                        align-items-center
                        text-secondary
                        mb-2 mb-md-0
                      "
                    >
                      Show
                      <select
                        name="ctl00$ContentPlaceHolder1$ddlRecordsPerPage"
                        v-model="per_page"
                        @change="changePerPage"
                        id="ContentPlaceHolder1_ddlRecordsPerPage"
                        class="custom-select custom-select-xs mx-2"
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected="selected" value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Records
                    </div>
                    <nav class="mb-4 mb-md-0" aria-label="page navigation">
                      <ul class="pagination pagination-sm mb-0">
                        <li class="page-item" :class="{ disabled: page == 0 }">
                          <span
                            class="page-link"
                            @click="
                              page = 0;
                              changePage();
                            "
                            >First</span
                          >
                        </li>

                        <li class="page-item" :class="{ disabled: page == 0 }">
                          <span
                            @click="
                              page = page - 1;
                              changePage();
                            "
                            class="page-link"
                            ><i class="fa fa-chevron-left small"></i></span
                          ><span class="sr-only">Previous</span>
                        </li>
                        <li class="page-item">
                          <span class="page-link text-nowrap"
                            >Page
                            <strong class="font-weight-medium">{{
                              page + 1
                            }}</strong>
                            of
                            <strong class="font-weight-medium">{{
                              allPages
                            }}</strong></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: page + 1 == allPages }"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Go to Next"
                        >
                          <span
                            class="page-link"
                            @click="
                              page = page + 1;
                              changePage();
                            "
                            aria-label="Next"
                            ><span aria-hidden="True"
                              ><i class="fa fa-chevron-right small"></i
                            ></span>
                            <span class="sr-only">Next</span></span
                          >
                        </li>
                        <li
                          class="page-item"
                          :class="{ disabled: page + 1 == allPages }"
                        >
                          <span
                            @click="
                              page = allPages - 1;
                              changePage();
                            "
                            class="page-link"
                            ><span aria-hidden="True">Last</span>
                            <span class="sr-only">Last</span></span
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </form>
              </div>
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  mt-3
                  mb-4
                "
              >
                <div class="d-flex align-items-center text-secondary"></div>
                <div class="small text-muted"></div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <comfooter></comfooter>
  </div>
</template>


<script>
import comheader from "@/components/serach_header";
import comfooter from "@/components/comfooter";
import { get_transfer_list, get_transaction_by_hash_height } from "@/API/api";
import { timeFrom } from "@/Utils/timeFrom";
import {} from "@/assets/js/commonjs.js";
import { ethers } from "ethers";
export default {
  data() {
    return {
      transferList: [],
      page: 0,
      per_page: 50,
      totalTokens: 0,
      allPages: 0,
      firstToken: 0,
      endToken: 0,
    };
  },
  components: {
    comheader,
    comfooter,
  },
  async mounted() {
    let that = this;
    that.get_transfer_list();
    document.onmouseup = function (e) {
      var e = e || window.event;
      var target = e.target || e.srcElement;
      var _con = $("#box"); //获取你的目标元素
      //1. 点击事件的对象不是目标区域本身
      //2. 事件对象同时也不是目标区域的子元素
      if (!_con.is(e.target) && _con.has(e.target).length === 0) {
        $("[data-toggle=popover]").popover("hide");
        // $("[data-toggle=popover]").popover("show");
      }
    };
  },
  methods: {
    get_transfer_list() {
      let that = this;
      get_transfer_list(that.per_page, that.page).then((res) => {

        that.transferList = res.data.content;
        that.totalTokens = res.data.totalElements;
        that.allPages = res.data.totalPages;
        if (that.transferList.length > 0) {
          that.firstToken = that.transferList[0];
          that.endToken = that.transferList[that.transferList.length - 1];
        }

        $(function () {
          that.showDetail();
        });
      });
    },
    changePerPage() {
      let that = this;
      that.page = 0;
      that.get_transfer_list();
    },
    changePage() {
      let that = this;
      that.get_transfer_list();
    },
    getContent(hash, height) {
      let that = this;
      get_transaction_by_hash_height(hash, height).then((res) => {
        let boxstr =
          `
          <h4 class="h5 mb-3">Additional Info</h4>
    <!-- Status -->
    <h5 class="font-size-1 font-weight-bold mb-1">Status:</h5>
    <span
      class="text-success"
      title="A Status code indicating if the top-level call succeeded or failed (applicable for Post BYZANTIUM blocks only)"
      ><i class="fa fa-check-circle mr-1"></i>Success</span
    >
    <hr class="hr-space" />
    <!-- Token Transfer -->
    <h5 class="font-size-1 font-weight-bold mb-1">Block:</h5>
    <div class="d-flex">

      <span class="mr-1">` +
          res.data.block_height +
          `</span
      >
    </div>
    <div class="d-flex">
      <span class="text-secondary mr-1">From</span
      ><span
        class="hash-tag text-truncate"
        data-toggle-second="toggle"
        title=""
        ><span
            class="hash-tag text-truncate hash-tag-custom-from"
            title=""
            >` +
          res.data.from +
          `</span
          ></span
      ><span class="text-secondary mr-1"> To</span
      ><span
        class="hash-tag text-truncate"
        data-toggle-second="toggle"
        title=""
        >` +
          res.data.to +
          `</span
      >
    </div>
    <hr class="hr-space" />
    <!-- Gas Info -->
    <h5 class="font-size-1 font-weight-bold mb-1">Gas Info:</h5>
    <div>
      ` +
          res.data.gas +
          ` Gas
    </div>
    <!-- End Gas Info -->
    <hr class="hr-space" />
    <!-- Nonce -->
    <h5 class="font-size-1 font-weight-bold mb-1">Nonce:</h5>
    <div>
      <span title="Transaction Nonce">` +
          res.data.nonce +
          `</span>
      <span
        class="text-secondary"
        title="Index position of Transaction in the block"
        >(in the position ` +
          res.data.position +
          `)</span
      >
    </div>
    <!-- End Nonce -->
    <hr class="hr-space" />
    <a
      href="/tx/` +
          res.data.hash +
          `/` +
          res.data.block_height +
          `"
      target="_blank"
      >See more details <i class="far fa-arrow-right small"></i
    ></a>
    <br />`;

        that.$nextTick(function () {
          document.querySelector("#box").innerHTML = boxstr;
        });
      });
    },
    showDetail() {
      let that = this;
      $("[data-toggle=popover]").popover({
        html: true, 
        content: "<div id='box'></div>",
      });
 

      $("[data-toggle=popover]").on("shown.bs.popover", function () {
      
        var ele = $(this);
        that.$nextTick(function () {
          var hash = ele.attr("hash");
          var height = ele.attr("height");
          if (hash != null && height != null) {
            that.getContent(hash, height);
          }
        });
      });
    },
    decimal(v, decimal) {
      if(v == null){
        v = 0;
      }
      if (
        Number.isInteger(
          Number(ethers.utils.formatUnits(ethers.BigNumber.from(v), decimal))
        )
      ) {
        return ethers.utils.commify(
          parseInt(ethers.utils.formatUnits(ethers.BigNumber.from(v), decimal))
        );
      }
      return ethers.utils.commify(
        ethers.utils.formatUnits(ethers.BigNumber.from(v), decimal)
      );
    },
  },
  filters: {
    timeFromat(date) {
      return timeFrom(date);
    },
  },
};
</script>
<style>
.fade:not(.show) {
  opacity: 1 !important;
}
#box {
  width: 276px;
  height: 350px;
}
.popover {
  /* position: relative; */
  padding: 10px;
  /* top: 150px !important;
    left: 10px !important; */
  will-change: transform !important;
  display: block;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
}
</style>
