<template>
  <div>
    <div class="wrapper">
      <comheader></comheader>
      <main id="content" role="main">
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age" />
        <input
          type="hidden"
          name="hdnDateTimeText"
          id="hdnDateTimeText"
          value="Date Time (UTC)"
        />
        <input
          type="hidden"
          name="hdnAgeTitle"
          id="hdnAgeTitle"
          value="Click to show Age Format"
        />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          value="Click to show Datetime Format"
        />
        <input
          type="hidden"
          name="hdnTxnText"
          id="hdnTxnText"
          value="Txn Fee"
        />
        <input
          type="hidden"
          name="hdnGasPriceText"
          id="hdnGasPriceText"
          value="Gas Price"
        />
        <input
          type="hidden"
          name="hdnTxnFeeTitle"
          id="hdnTxnFeeTitle"
          value="(Gas Price * Gas Used by Txns) in Ether"
        />
        <input
          type="hidden"
          name="hdnGasPriceTitle"
          id="hdnGasPriceTitle"
          value="Gas Price in Gwei"
        />
        <div class="container">
          <div
            class="
              d-md-flex
              justify-content-between
              align-items-center
              border-bottom
              py-3
            "
          >
            <div class="mb-1 mb-md-0">
              <h1 class="h4 mb-0">
                <span class="d-block">Ethereum Daily Transactions Chart</span>
              </h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb small p-0 mb-0">
                <li class="breadcrumb-item">
                  <a href="/charts">Charts &amp; Stats</a>
                </li>
                <li class="breadcrumb-item">
                  <a
                    href="/charts#blockchainData"
                    id="ContentPlaceHolder1_aChartRegion"
                    >Blockchain Data</a
                  >
                </li>
                <li class="breadcrumb-item active">
                  Ethereum Daily Transactions Chart
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="container">
          <div class="card mt-3 mb-4">
            <div class="card-body">
              <span
                >The chart highlights the total number of transactions on the
                Ethereum blockchain with daily individual breakdown for average
                difficulty, estimated hash rate, average block time and size,
                total block and uncle block count and total new address
                seen.</span
              >
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 text-center">
                  <p class="mt-2 mb-2">
                    <i class="fa fa-lightbulb mr-2"></i> Highest number of
                    <b>1,716,600</b> transactions on Sunday, May 9, 2021
                  </p>
                  <hr class="d-block d-md-none hr-space-lg" />
                </div>
                <div
                  class="
                    col-md-6
                    text-center
                    u-ver-divider u-ver-divider--left u-ver-divider--none-md
                  "
                >
                  <p class="mt-2 mb-2">
                    <i class="fa fa-lightbulb mr-2"></i> Lowest number of
                    <b>1,329</b> transactions on Sunday, August 9, 2015
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body space-bottom-1">
              <div
                class="content"
                id="containerchart"
                style="height: 550px; min-width: 310px; overflow: hidden"
                data-highcharts-chart="0"
              >
                <highcharts :options="chartOptions"></highcharts>
              </div>
            </div>
          </div>
          <div class="my-4 text-right">
            Download: <a href="/chart/tx?output=csv">CSV Data</a> (Attribution
            Required) &nbsp;&nbsp;&nbsp;
          </div>
          <br />
        </div>
      </main>
      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <comfooter></comfooter>
  </div>
</template>

<script>
// @ is an alias to /src
import comheader from "@/components/comheader";
import comfooter from "@/components/comfooter";
import { Chart } from "highcharts-vue";
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import loadExporting from 'highcharts/modules/exporting'
exportingInit(Highcharts)
export default {
  name: "Home",
  components: {
    comheader,
    comfooter,
    highcharts: Chart,
  },
  data() {
    return {
      showCharts: false,
      chartOptions: {

        chart: {
          spacingTop: 10,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 10,
          height: 550,
        },

        title: {
          text: " Ethereum Daily Transactions Chart ",
          align: "center",
        },
        subtitle: {
          useHTML:false,
          align: "center",
          text: 'Source: Etherscan.io</br>Click and drag in the plot area to zoom in'
        },
        exporting:{
              enabled:true ,  
                sourceWidth: 900,     //下载图片的宽度
                sourceHeight: 550,  //下载图片的高度
         },

        xAxis: {
          title: { text: "" },
          lineWidth: 1,
          minorTickLength: 0,
          tickLength: 0,
          labels: {
            step: 1,
          },
          categories: [
            "Aug 9",
            "Aug 10",
            "Aug 11",
            "Aug 12",
            "Aug 13",
            "Aug 14",
            "Aug 15",
            "Aug 16",
            "Aug 17",
            "Aug 18",
            "Aug 19",
            "Aug 20",
            "Aug 21",
            "Aug 22",
            "Aug 23",
          ],
        },

        yAxis: {
          labels: {
            enabled: true,
          },
          gridLineWidth: 0,
          title: {
            text: 'Transactions per Day',
            style:{
              'color':'#666666',
              'fontSize':'12px'
            }
          },
        },

        legend: {
          enabled: false,
        },

        credits: {
          enabled: false,
        },

        tooltip: {
          formatter: function (e) {
            //let obj = data.chart
            let s =
              '<span style="font-size:10px">' +
              this.point.friendlydate +
              '</span><br><table><tr><td style="padding:0">' +
              '<span style="color:' +
              this.series.color +
              '">Transactions: </a></span><b>' +
              this.point.formattedValue +
              "</b><br>" +
              "<br>" +
              "Price: " +
              this.point.price +
              "";
            ("</td></tr></table>");
            return s;
          },
        },

        plotOptions: {
          series: {
            color: "#1e2022",
            enableMouseTracking: true,
            lineWidth: 1,
            shadow: false,
            animation: false,
            cursor: "pointer",
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            marker: {
              radius: 0,
            },
            point: {
              events: {
                select: function (e) {
                  location.href = "txs?dt=" + this.options.dt;
                },
              },
            },
          },
          column: {
            pointPadding: 0.1,
            borderWidth: 0,
          },
        },

        series: [
          {
            name: "Transactions",
            type: "spline",
            lineWidth:1,
            data: [
              {
                y: 1218594,
                dt: "1628467200",
                formattedValue: "1,218,594",
                friendlydate: "Monday, August 9, 2021",
                price: "$3,164.94",
              },
              {
                y: 1212289,
                dt: "1628553600",
                formattedValue: "1,212,289",
                friendlydate: "Tuesday, August 10, 2021",
                price: "$3,141.67",
              },
              {
                y: 1234409,
                dt: "1628640000",
                formattedValue: "1,234,409",
                friendlydate: "Wednesday, August 11, 2021",
                price: "$3,168.24",
              },
              {
                y: 1195283,
                dt: "1628726400",
                formattedValue: "1,195,283",
                friendlydate: "Thursday, August 12, 2021",
                price: "$3,047.29",
              },
              {
                y: 1197050,
                dt: "1628812800",
                formattedValue: "1,197,050",
                friendlydate: "Friday, August 13, 2021",
                price: "$3,324.26",
              },
              {
                y: 1159942,
                dt: "1628899200",
                formattedValue: "1,159,942",
                friendlydate: "Saturday, August 14, 2021",
                price: "$3,266.71",
              },
              {
                y: 1125295,
                dt: "1628985600",
                formattedValue: "1,125,295",
                friendlydate: "Sunday, August 15, 2021",
                price: "$3,311.12",
              },
              {
                y: 1253968,
                dt: "1629072000",
                formattedValue: "1,253,968",
                friendlydate: "Monday, August 16, 2021",
                price: "$3,147.63",
              },
              {
                y: 1252163,
                dt: "1629158400",
                formattedValue: "1,252,163",
                friendlydate: "Tuesday, August 17, 2021",
                price: "$3,011.96",
              },
              {
                y: 1201544,
                dt: "1629244800",
                formattedValue: "1,201,544",
                friendlydate: "Wednesday, August 18, 2021",
                price: "$3,013.52",
              },
              {
                y: 1255453,
                dt: "1629331200",
                formattedValue: "1,255,453",
                friendlydate: "Thursday, August 19, 2021",
                price: "$3,184.74",
              },
              {
                y: 1260054,
                dt: "1629417600",
                formattedValue: "1,260,054",
                friendlydate: "Friday, August 20, 2021",
                price: "$3,286.50",
              },
              {
                y: 1186758,
                dt: "1629504000",
                formattedValue: "1,186,758",
                friendlydate: "Saturday, August 21, 2021",
                price: "$3,226.59",
              },
              {
                y: 1183893,
                dt: "1629590400",
                formattedValue: "1,183,893",
                friendlydate: "Sunday, August 22, 2021",
                price: "$3,241.65",
              },
              {
                y: 1245624,
                dt: "1629676800",
                formattedValue: "1,245,624",
                friendlydate: "Monday, August 23, 2021",
                price: "$3,322.71",
              },
            ],
            allowPointSelect: true,
            pointStart: 0,
          },
        ],
      },
    };
  },
  mounted() {
    let that = this;
  },
  methods: {
    isShowCharts() {
      let that = this;
      that.showCharts = !that.showCharts;
    },
  },
};
</script>
