@@ -0,0 +1,1132 @@
<template>
  <div>
    <div class="wrapper">
      <comheader :index="2"></comheader>

      <main id="content" role="main">
        <div class="container py-3 mn-b3">
          <div class="d-lg-flex justify-content-between align-items-center">
            <div class="mb-3 mb-lg-0">
              <h1 class="h4 mb-0">Transaction Details</h1>
            </div>
          </div>
        </div>

        <div class="container space-bottom-2">
          <div class="card">
            <div
              class="card-header sticky-card-header d-flex justify-content-between p-0"
            >
              <ul
                class="nav nav-custom nav-borderless nav_tabs1"
                id="nav_tabs"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: actTab == '1' }"
                    id="home-tab"
                    data-toggle="tab"
                    aria-controls="home"
                    aria-selected="true"
                    @click="actTab = 1"
                    >Overview</a
                  >
                </li>
                <li class="nav-item" v-if="logs">
                  <a
                    class="nav-link"
                    :class="{ active: actTab == '2' }"
                    id="home-tab2"
                    data-toggle="tab2"
                    aria-controls="home2"
                    aria-selected="true"
                    @click="actTab = 2"
                    >Logs</a
                  >
                </li>
              </ul>
            </div>
            <div class="tab-content" v-if="actTab == 2" id="myTabContent2">
              <div
                class="tab-pane fade active show"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div id="ContentPlaceHolder1_maintable" class="card-body">
                  <div class="row align-items-center mt-1">
                 
                    <hr class="hr-space" v-if="showNonce" />
 
                      <div
                        class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                      > Logs:
                      </div>
                      <div class="col-md-9"> 
                        <div id="rawtab" data-target-group="inputDataGroup">
                         
                            <textarea
                              v-model="logs"
                              readonly=""
                              spellcheck="false"
                              class="form-control bg-light text-secondary text-monospace p-3"
                              rows="6"
                              id="inputdata"
                              style="height: auto;min-height:200px"
                            >
                             </textarea > 
                             
                        </div>
                         
                      </div> 

                    <!-- <hr class="hr-space" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" v-else id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div id="ContentPlaceHolder1_maintable" class="card-body">
                  <div class="row align-items-center mt-1">
                    <div
                      class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="A TxHash or transaction hash is a unique 66 characters identifier that is generated whenever a transaction is executed."
                        placement="top"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                          slot="reference"
                        ></i> </el-popover
                      >Transaction Hash:
                    </div>
                    <div class="col-md-9">
                      <span id="spanTxHash" class="mr-1">{{ txInfo.hash }}</span>

                      <el-tooltip content="Copy Txn Hash to clipboard" placement="top">
                        <a
                          class="js-clipboard text-muted"
                          href="javascript:;"
                          v-clipboard:copy="txInfo.hash"
                          v-clipboard:success="onCopy1"
                          v-clipboard:error="onError"
                        >
                          <span
                            id="spanTxHashLinkIcon"
                            class="far"
                            :class="{
                              'fa-copy': !isSuccess_copy1,
                              'fa-check-circle-o': isSuccess_copy1,
                            }"
                          ></span>
                        </a>
                      </el-tooltip>
                      {{ isSuccess_copy1 ? "Copied" : "" }}
                    </div>
                  </div>

                  <hr class="hr-space" />
                  <div class="row align-items-center mn-3">
                    <div
                      class="col-auto col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The status of the transaction."
                        placement="top"
                        max-width="260"
                        trigger="hover"
                      >
                        <i
                          class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                          slot="reference"
                        ></i> </el-popover
                      >Status:
                    </div>
                    <div class="col col-md-9">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="A Status code indicating if the top-level call succeeded or failed (applicable for Post BYZANTIUM blocks only)"
                        placement="top"
                        width="200"
                      >
                        <span
                          v-if="txInfo.status == true"
                          class="u-label u-label--sm u-label--success rounded"
                          ><i class="fa fa-check-circle mr-1"></i>Success</span
                        >
                        <span
                          v-if="txInfo.status == false"
                          class="u-label u-label--sm u-label--danger rounded"
                          ><i class="fa fa-times-circle-o"></i> Fail</span
                        >
                      </el-tooltip>
                    </div>
                  </div>

                  <hr class="hr-space" />

                  <div class="row align-items-center">
                    <div
                      class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The number of the block in which the transaction was recorded. Block confirmation indicate how many blocks since the transaction is mined."
                        placement="top"
                        max-width="260"
                        width="260"
                        trigger="hover"
                      >
                        <i
                          class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                          slot="reference"
                        ></i> </el-popover
                      >Block:
                    </div>
                    <div class="col-md-9">
                      <a :href="'/blocksdetail/' + txInfo.block_height">{{
                        txInfo.block_height
                      }}</a>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Number of blocks mined since"
                        placement="top"
                        width="200"
                      >
                        <!-- <span
                          class="
                            u-label
                            u-label--xs
                            u-label--badge-in
                            u-label--secondary
                            ml-1
                          "
                          >2 Block Confirmations</span
                        > -->
                      </el-tooltip>
                    </div>
                  </div>

                  <div id="ContentPlaceHolder1_divTimeStamp">
                    <hr class="hr-space" />

                    <div class="row align-items-center">
                      <div
                        class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                      >
                        <el-popover
                          popper-class="new-ele-popper"
                          content="The date and time at which a transaction is mined."
                          placement="top"
                          max-width="200"
                          width="200"
                          trigger="hover"
                        >
                          <i
                            class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                            slot="reference"
                          ></i> </el-popover
                        >Timestamp:
                      </div>
                      <div class="col-md-9">
                        <span id="clock"></span><i class="far fa-clock small mr-1"></i
                        >{{ txInfo.created_at | timeFromat }} ({{
                          txInfo.created_at | dateFromat
                        }})
                      </div>
                    </div>
                  </div>
                  <hr class="hr-space" />

                  <div class="row align-items-center">
                    <div
                      class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The sending party of the transaction (could be from a contract address)."
                        placement="top"
                        max-width="200"
                        width="200"
                        trigger="hover"
                      >
                        <i
                          class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                          slot="reference"
                        ></i> </el-popover
                      >From:
                    </div>
                    <div class="col-md-9">
                      <span v-if="ifContractFrom">Contract </span>
                      <a
                        id="addressCopy"
                        class="mr-1"
                        :href="'/txs?address=' + txInfo.from"
                        >{{ txInfo.from }}</a
                      >
                      <el-tooltip
                        content="Copy From Address to clipboard"
                        placement="top"
                      >
                        <a
                          class="js-clipboard text-muted ml-1"
                          href="javascript:;"
                          v-clipboard:copy="txInfo.from"
                          v-clipboard:success="onCopy2"
                          v-clipboard:error="onError"
                        >
                          <span
                            id="fromAddressLinkIcon"
                            class="far"
                            :class="{
                              'fa-copy': !isSuccess_copy2,
                              'fa-check-circle-o': isSuccess_copy2,
                            }"
                          ></span>
                        </a>
                      </el-tooltip>
                      {{ isSuccess_copy2 ? "Copied" : "" }}
                    </div>
                  </div>

                  <hr class="hr-space" />

                  <div class="row">
                    <div
                      class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The receiving party of the transaction (could be a contract address)."
                        placement="top"
                        max-width="200"
                        width="200"
                        trigger="hover"
                      >
                        <i
                          class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                          slot="reference"
                        ></i> </el-popover
                      >To:
                    </div>
                    <div class="col-md-9">
                      <span v-if="ifContractTo">Contract </span>
                      <a
                        id="contractCopy"
                        :href="'/txs?address=' + txInfo.to"
                        class="wordwrap mr-1"
                        >{{ txInfo.to }}</a
                      >
                      <el-tooltip content="Copy To Address to clipboard" placement="top">
                        <a
                          class="js-clipboard text-muted ml-1"
                          href="javascript:;"
                          v-clipboard:copy="txInfo.to"
                          v-clipboard:success="onCopy3"
                          v-clipboard:error="onError"
                        >
                          <span
                            id="spanToAddResult"
                            class="far"
                            :class="{
                              'fa-copy': !isSuccess_copy3,
                              'fa-check-circle-o': isSuccess_copy3,
                            }"
                          ></span>
                        </a>
                      </el-tooltip>
                      {{ isSuccess_copy3 ? "Copied" : "" }}
                    </div>
                  </div>

                  <hr class="hr-space" />

                  <div class="row align-items-center mn-3">
                    <div
                      class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="The value being transacted in Ether and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction."
                        placement="top"
                        max-width="250"
                        width="250"
                        trigger="hover"
                      >
                        <i
                          class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                          slot="reference"
                        ></i> </el-popover
                      >Value:
                    </div>
                    <div class="col-md-9">
                      <span id="ContentPlaceHolder1_spanValue">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            'The amount of ' +
                            nodeData.code +
                            ' to be transferred to the recipient with the transaction'
                          "
                          placement="top"
                          width="200"
                        >
                          <span data-toggle="tooltip" title="" data-original-title=""
                            ><span
                              class="u-label u-label--value u-label--secondary text-dark rounded mr-1"
                              >{{ txInfo.amount | fromWei }} {{ nodeData.code }}</span
                            >
                          </span>
                        </el-tooltip>
                        ($0.00)
                      </span>
                    </div>
                  </div>

                  <hr class="hr-space" />
                  <div class="row align-items-center">
                    <div
                      class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                    >
                      <el-popover
                        popper-class="new-ele-popper"
                        content="Cost per unit of gas specified for the transaction, in Ether and Gwei. The higher the gas price the higher chance of getting included in a block."
                        placement="top"
                        trigger="hover"
                        width="250"
                      >
                        <i
                          class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                          slot="reference"
                        ></i> </el-popover
                      >Gas Price:
                    </div>
                    <div class="col-md-9">
                      <span
                        id="ContentPlaceHolder1_spanGasPrice"
                        title=""
                        data-toggle="tooltip"
                        data-original-title="The price offered to the miner to purchase this amount of GAS （per GAS）"
                        >{{ txInfo.gas_price | fromWei }} {{ nodeData.code }} ({{
                          txInfo.gas_price | valueFormat
                        }}
                        Gwei)</span
                      >
                    </div>
                  </div>

                  <hr class="hr-space" />

                  <div
                    id="ContentPlaceHolder1_collapseContent"
                    class="collapse show"
                    style=""
                  >
                    <!-- <div class="row align-items-center">
                      <div
                        class="
                          col-md-3
                          font-weight-bold font-weight-sm-normal
                          mb-1 mb-md-0
                        "
                      >
                        <el-popover
                          popper-class="new-ele-popper"
                          content="Maximum amount of gas provided for the transaction. For normal Eth transfers, the value is 21,000. For contract this value is higher and bound by block gas limit."
                          placement="top"
                          trigger="hover"
                          width="250"
                        >
                          <i
                            class="
                              fal
                              fa-question-circle
                              text-secondary
                              d-sm-inline-block
                              mr-1
                            "
                            slot="reference"
                          ></i> </el-popover
                        >Gas Limit:
                      </div>
                      <div class="col-md-9">
                        <span
                          id="ContentPlaceHolder1_spanGasLimit"
                          title=""
                          data-toggle="tooltip"
                          data-original-title="The amount of GAS supplied for this transaction to happen"
                          >60000000</span
                        >
                      </div>
                    </div>

                    <hr class="hr-space" /> --> 

                    <div class="row mn-3" v-if="showNonce">
                      <div
                        class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                      >
                        <el-popover
                          popper-class="new-ele-popper"
                          content="Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be the 11th transaction sent from the sender's address."
                          placement="top"
                          trigger="hover"
                          width="250"
                        >
                          <i
                            class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                            slot="reference"
                          ></i> </el-popover
                        >Nonce

                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Index position of Transaction in the block"
                          placement="top"
                          width="200"
                        >
                          <span
                            id="ContentPlaceHolder1_spanNonce"
                            class="u-label u-label--xs u-label--badge-out u-label--secondary ml-1"
                            >Position</span
                          >
                        </el-tooltip>
                      </div>
                      <div class="col-md-9">
                        <span
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Transaction Nonce"
                          >{{ txInfo.nonce }}</span
                        >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Index position of Transaction in the block"
                          placement="top"
                          width="200"
                        >
                          <span
                            data-toggle="tooltip"
                            title=""
                            class="u-label u-label--xs u-label--badge-out u-label--secondary ml-1 mr-3"
                            >{{ txInfo.position }}</span
                          >
                        </el-tooltip>
                      </div>
                    </div>

                    <hr class="hr-space" v-if="showNonce" />

                    <div class="row">
                      <div
                        class="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"
                      >
                        <el-popover
                          popper-class="new-ele-popper"
                          content="Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be the 11th transaction sent from the sender's address."
                          placement="top"
                          trigger="hover"
                          width="250"
                        >
                          <i
                            class="fal fa-question-circle text-secondary d-sm-inline-block mr-1"
                            slot="reference"
                          ></i> </el-popover
                        >Input Data:
                      </div>
                      <div class="col-md-9">
                        <div id="rawtab" data-target-group="inputDataGroup">
                          <span
                            data-toggle="tooltip"
                            title=""
                            data-original-title="The binary data that formed the input to the transaction, either the input data if it was a message call or the contract initialisation if it was a contract creation"
                          >
                            <textarea
                              v-model="decodeData"
                              readonly=""
                              spellcheck="false"
                              class="form-control bg-light text-secondary text-monospace p-3"
                              rows="2"
                              id="inputdata"
                              style="height: 100px"
                            ></textarea
                            ><span id="rawinput" style="display: none">0x</span>
                          </span>
                          <div
                            id="ContentPlaceHolder1_btnconvert222"
                            class="d-flex align-items-center mt-2"
                          >
                            <div class="position-relative mr-2">
                              <div class="dropdown">
                                <button
                                  class="btn btn-xss btn-secondary dropdown-toggle"
                                  @click="showMenu"
                                  type="button"
                                  id="btnconvert222_1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  View Input As
                                </button>
                                <div
                                  class="dropdown-menu"
                                  :class="{ show: isShow }"
                                  aria-labelledby="btnconvert222_1"
                                  style="
                                    position: absolute;
                                    transform: translate3d(0px, 27px, 0px);
                                    top: 0px;
                                    left: 0px;
                                    will-change: transform;
                                  "
                                  x-placement="bottom-start"
                                >
                                  <a
                                    id="convert_default"
                                    href="javascript:;"
                                    class="dropdown-item"
                                    @click="changeType(0)"
                                    >Default View</a
                                  >
                                  <a
                                    id="convert_original"
                                    href="javascript:;"
                                    class="dropdown-item"
                                    @click="changeType(1)"
                                    >Original</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="decodetab"
                          data-target-group="inputDataGroup"
                          style="display: none; opacity: 0"
                        >
                          <span id="loadingtxframe">
                            <div id="overlayMain" align="center" style="display: block">
                              <img src="/images/main/loadingblock.svg" alt="Loading" />
                            </div>
                          </span>
                          <div id="decodebox" style="display: none">
                            <div
                              class="js-scrollbar scrollbox mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar"
                              style="position: relative; overflow: visible"
                            >
                              <div
                                id="mCSB_1"
                                class="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside"
                                style="max-height: none"
                                tabindex="0"
                              >
                                <div
                                  id="mCSB_1_container"
                                  class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                                  style="position: relative; top: 0; left: 0"
                                  dir="ltr"
                                >
                                  <div
                                    id="inputDecode"
                                    class="bg-light table-responsive"
                                  ></div>
                                </div>
                              </div>
                              <div
                                id="mCSB_1_scrollbar_vertical"
                                class="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical"
                                style="display: none"
                              >
                                <div class="mCSB_draggerContainer">
                                  <div
                                    id="mCSB_1_dragger_vertical"
                                    class="mCSB_dragger"
                                    style="position: absolute; min-height: 50px; top: 0px"
                                  >
                                    <div
                                      class="mCSB_dragger_bar"
                                      style="line-height: 50px"
                                    ></div>
                                  </div>
                                  <div class="mCSB_draggerRail"></div>
                                </div>
                              </div>
                            </div>
                            <a
                              href="javascript:;"
                              class="js-animation-link btn btn-xss btn-secondary mt-2"
                              onclick="javascript:btnSwitchClick();"
                              ><i class="fa fa-undo txhash-icon"></i> Switch Back</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <hr class="hr-space" /> -->
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="internal"
                role="tabpanel"
                aria-labelledby="internal-tab"
              >
                <div
                  class="ml-auto float-right mr-4 mt-3 mb-4"
                  id="divswitch"
                  data-container="body"
                  data-toggle="popover"
                  data-placement="top"
                  data-original-title=""
                  title=""
                  data-content="Toggle between Simplified and Advanced view. The 'Advanced' view also shows zero value ETH transfers, while the 'Simple' view only shows ETH transfers with value. Name tags integration is not available in advanced view"
                >
                  <div class="d-flex">
                    <label
                      class="checkbox-switch mb-0"
                      id="labelswitch"
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title=""
                      title=""
                    >
                      <input
                        type="checkbox"
                        class="checkbox-switch__input"
                        id="customSwitch1"
                        onclick="toggleMode();"
                      />
                      <span class="checkbox-switch__slider"></span>
                    </label>
                    <span class="ml-1" id="toggletext">Simple</span>
                  </div>
                </div>
                <div class="card-body mt-1">
                  Invoked Transactions
                  <div
                    id="ContentPlaceHolder1_divinternaltable"
                    class="table-responsive mb-2 mb-md-0"
                  >
                    <table class="table table-hover table-align-middle">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">Type Trace Address</th>
                          <th scope="col" width="180">From</th>
                          <th scope="col" width="30"></th>
                          <th scope="col">To</th>
                          <th scope="col">Value</th>
                          <th scope="col">Gas Limit</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="eventlog"
                role="tabpanel"
                aria-labelledby="eventlog-tab"
              >
                <div class="card-body">
                  <h6 class="font-weight-normal mb-4">Transaction Receipt Event Logs</h6>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="accesslist"
                role="tabpanel"
                aria-labelledby="accesslist-tab"
              >
                <div class="card-body">
                  <h6 class="font-weight-normal mb-4">Access List</h6>
                  <pre>[]</pre>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="comments"
                role="tabpanel"
                aria-labelledby="comments-tab"
              >
                <div class="card-body">
                  <p>
                    Make sure to use the "Vote Down" button for any spammy posts, and the
                    "Vote Up" for interesting conversations.
                  </p>
                  <br />
                  <div class="panel-body">
                    <div>
                      <div id="disqus_thread">
                        <iframe
                          id="dsq-app1080"
                          name="dsq-app1080"
                          allowtransparency="true"
                          frameborder="0"
                          scrolling="no"
                          tabindex="0"
                          title="Disqus"
                          width="100%"
                          style="
                            width: 1px !important;
                            min-width: 100% !important;
                            border: none !important;
                            overflow: hidden !important;
                            height: 0px !important;
                          "
                          horizontalscrolling="no"
                          verticalscrolling="no"
                        ></iframe
                        ><iframe
                          id="indicator-north"
                          name="indicator-north"
                          allowtransparency="true"
                          frameborder="0"
                          scrolling="no"
                          tabindex="0"
                          title="Disqus"
                          style="
                            width: 0px !important;
                            border: none !important;
                            overflow: hidden !important;
                            top: 0px !important;
                            min-width: 0px !important;
                            max-width: 0px !important;
                            position: fixed !important;
                            z-index: 2147483646 !important;
                            height: 0px !important;
                            min-height: 0px !important;
                            max-height: 0px !important;
                            display: none !important;
                          "
                        ></iframe
                        ><iframe
                          id="indicator-south"
                          name="indicator-south"
                          allowtransparency="true"
                          frameborder="0"
                          scrolling="no"
                          tabindex="0"
                          title="Disqus"
                          style="
                            width: 0px !important;
                            border: none !important;
                            overflow: hidden !important;
                            bottom: 0px !important;
                            min-width: 0px !important;
                            max-width: 0px !important;
                            position: fixed !important;
                            z-index: 2147483646 !important;
                            height: 0px !important;
                            min-height: 0px !important;
                            max-height: 0px !important;
                            display: none !important;
                          "
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="statechange"
                role="tabpanel"
                aria-labelledby="statechange-tab"
              >
                <div class="card-body">
                  <div id="loadingStateChangeFrame" style="display: none">
                    <div id="overlay" class="py-3 text-center">
                      <img src="/images/main/loadingblock.svg" alt="Loading" />
                    </div>
                  </div>
                  <div
                    class="table-responsive"
                    style="overflow: auto; -webkit-overflow-scrolling: touch"
                  >
                    <iframe
                      width="100%"
                      allowtransparency="true"
                      background="transparent"
                      id="statechangeframe"
                      src="/accountstatediff?m=normal&amp;a=0xa72ad21d63020929904ce30056d34595657d469bcc8b91568a714a507d082d39"
                      frameborder="0"
                      scrolling="yes"
                      style="height: 500px"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pendingtxlog"
                role="tabpanel"
                aria-labelledby="pendingtxeventlog-tab"
              >
                <div class="card-body">
                  <div id="loadingPendingTxEventLogFrame">
                    <div id="overlay" class="py-3 text-center">
                      <img src="/images/main/loadingblock.svg" alt="Loading" />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <iframe
                      width="100%"
                      allowtransparency="true"
                      background="transparent"
                      id="pendingtxeventlogframe"
                      src=""
                      frameborder="0"
                      scrolling="yes"
                      style="height: 500px; min-height: 500px"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div id="push"></div>
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        id="emailSubscribeModalBox"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close close-md"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div class="text-center py-5-alt px-4">
                <div id="emailSubscribeModalBoxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <comfooter></comfooter>
  </div>
</template>

<script>
// @ is an alias to /src
import comheader from "@/components/serach_header";
import comfooter from "@/components/comfooter";
import { if_contract_address } from "@/API/api";
import {
  get_transaction_by_hash,
  get_node_info,
  get_transaction_by_hash_height,
} from "@/API/api";
import { timeFrom } from "@/Utils/timeFrom";
import Web3 from "web3";
import abi from "./abi.json";
import erc20Abi from "../contract/erc20Abi.json";
import allAbi from "../../public/allAbi.json";
const ethers = require("ethers");
import { decodeLog } from "@/Utils/decodeLog.js"; 

const InputDataDecoder = require("ethereum-input-data-decoder");

export default {
  name: "Home",
  components: {
    comheader,
    comfooter,
  },
  data() {
    return {
      showNonce: false,
      status: true,
      isSuccess_copy1: false,
      isSuccess_copy2: false,
      isSuccess_copy3: false,
      timer: null,
      txInfo: {},
      nodeData: {}, //获取节点信息
      ifContractFrom: false,
      ifContractTo: false,
      allAbi: null,
      isShow: false,
      decodeData: "",
      actTab: 1,
      logs: null,
    };
  },
  async mounted() {
    console.log("scanAdmin");
    this.showNonce = window.localStorage.getItem("scanAdmin") || false;

    let that = this;

    that.getNodeData();

    that.allAbi = abi;

    if (that.$route.params.height == undefined) {
      await get_transaction_by_hash(that.$route.params.hash).then((res) => {
        that.txInfo = res.data;
        console.log(res);
        const decoder = new InputDataDecoder(that.allAbi);
        const result = decoder.decodeData(that.txInfo.input);

        if (result.method != null) {
          var inputStr = that.txInfo.input;
          let method = inputStr.slice(0, 10);

          var dataStr = inputStr.slice(10);

          var dataArr = [];

          for (let i = 0; i < dataStr.length / 64; i++) {
            let dataT = dataStr.slice(i * 64, 64 * (i + 1));
            dataArr.push(dataT);
          }

          var showStr = "Function: " + result.method + "(";
          for (let j = 0; j < result.types.length; j++) {
            let obj1 = result.types[j];
            let obj2 = result.names[j];

            showStr = showStr + obj1 + " " + obj2;
            if (j !== result.types.length - 1) {
              showStr = showStr + ", ";
            }
          }
          showStr = showStr + ")" + "\n" + "\n";
          showStr = showStr + "MethodID: " + method + "\n";

          for (let k = 0; k < dataArr.length; k++) {
            let obj = dataArr[k];
            showStr = showStr + "[" + k + "]: " + obj + "\n";
          }

          that.txInfo.inputDecodeData = showStr;

          that.decodeData = showStr;
        } else {
          that.txInfo.inputDecodeData = that.txInfo.input;
          that.decodeData = that.txInfo.input;
        }
      });
    } else {
      await get_transaction_by_hash_height(
        that.$route.params.hash,
        that.$route.params.height
      ).then((res) => {
        that.txInfo = res.data;
        console.log(res);

        const decoder = new InputDataDecoder(that.allAbi);
        const result = decoder.decodeData(that.txInfo.input);

        if (result.method != null) {
          var inputStr = that.txInfo.input;
          let method = inputStr.slice(0, 10);

          var dataStr = inputStr.slice(10);

          var dataArr = [];

          for (let i = 0; i < dataStr.length / 64; i++) {
            let dataT = dataStr.slice(i * 64, 64 * (i + 1));
            dataArr.push(dataT);
          }

          var showStr = "Function: " + result.method + "(";
          for (let j = 0; j < result.types.length; j++) {
            let obj1 = result.types[j];
            let obj2 = result.names[j];

            showStr = showStr + obj1 + " " + obj2;
            if (j !== result.types.length - 1) {
              showStr = showStr + ", ";
            }
          }
          showStr = showStr + ")" + "\n" + "\n";
          showStr = showStr + "MethodID: " + method + "\n";

          for (let k = 0; k < dataArr.length; k++) {
            let obj = dataArr[k];
            showStr = showStr + "[" + k + "]: " + obj + "\n";
          }

          that.txInfo.inputDecodeData = showStr;
          that.decodeData = showStr; //JSON.stringify(result);
        } else {
          that.txInfo.inputDecodeData = that.txInfo.input;
          that.decodeData = that.txInfo.input;
        }
      });
    }

      await decodeLog(that.txInfo.hash,allAbi).then(res=>{
        that.logs =res 
      }).catch(err=>{  
        if (err=='Not log') {
          
          that.logs ='Not logs'
        }else{

          that.logs ='---'
        }
     });
 
    that.ifContractAddress(that.txInfo.from, "from");
    that.ifContractAddress(that.txInfo.to, "to");
  },
  filters: {
    fromWei(value) {
      if (value == null) {
        return 0;
      }

      var web3 = new Web3();
      var value = web3.utils.fromWei(value.toString(), "ether");
      return value;
    },
    str_Address(address) {
      let str1 = address.slice(0, 4);
      let str2 = address.slice(-4);

      return str1 + "..." + str2;
    },
    timeFromat(date) {
      return timeFrom(date);
    },
    dateFromat(date) {
      return new Date(parseInt(date) * 1000).toUTCString();
    },
    valueFormat(data) {
      var res = data / Math.pow(10, 9);
      return res;
    },
  },
  methods: {
    changeType(type) {
      let that = this;
      if (type == 0) {
        that.decodeData = that.txInfo.inputDecodeData;
      } else {
        that.decodeData = that.txInfo.input;
      }
      that.isShow = false;
    },
    async ifContractAddress(addr, state) {
      let that = this;

      let res = await if_contract_address(addr);

      if (state == "from") {
        if (res) {
          that.ifContractFrom = true;
        } else {
          that.ifContracFrom = false;
        }
      } else {
        if (res) {
          that.ifContractTo = true;
        } else {
          that.ifContractTo = false;
        }
      }
    },
    async getNodeData() {
      let that = this;
      get_node_info().then((res) => {
        that.nodeData = res;
        var web3 = new Web3(new Web3.providers.HttpProvider(that.nodeData.node_url));
      });
    },

    onCopy1: function (e) {
      let that = this;

      that.isSuccess_copy1 = true;
      that.timer = setTimeout(function () {
        that.isSuccess_copy1 = false;
      }, 2000);
    },

    onCopy2: function (e) {
      let that = this;

      that.isSuccess_copy2 = true;
      that.timer = setTimeout(function () {
        that.isSuccess_copy2 = false;
      }, 2000);
    },
    onCopy3: function (e) {
      let that = this;

      that.isSuccess_copy3 = true;
      that.timer = setTimeout(function () {
        that.isSuccess_copy3 = false;
      }, 2000);
    },
    showMenu() {
      let that = this;
      if (that.isShow) {
        that.isShow = false;
      } else {
        that.isShow = true;
      }
    },
    onError: function (e) {
      let that = this;
      this.$message({
        showClose: true,
        message: "复制失败",
        type: "warning",
      });
    },
  },
  beforeDestroy() {
    let that = this;
    if (that.timer != null) {
      clearTimeout(that.timer);
    }
  },
};
</script>
<style scoped>
#btnconvert222_1::after {
  content: none;
}
</style>
